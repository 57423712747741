import React,  { useState }  from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import DataTable from '../DataTableComponents';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2' 
import Modal from '@mui/material/Modal';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius:'9px',
    p: 4,
    display: "flex",
    flexDirection: "column",
    height: 500,
    overflow: "hidden",
    overflowY: "scroll",
  };


  const VENTAS = gql`
    query Query {
  Sales {
    address
    clientId
    date
    products {
      funkos {
        category
        discount
        name
        nomenclature
        price
        quantity
        typeDiscount
        variation
      }
      productos {
        category
        discount
        name
        color
        quantity
        typeDiscount
        price
        flor
      }
    }
    id
    discount
    saleQuantity
    total
    status
  }
}
`;

const CRUD = gql`
mutation Mutation($status: String!, $saleStatusId: String) {
  SaleStatus(status: $status, id: $saleStatusId) {
    id
    status
  }
}
`;


const Ventas = ()=>{

    const {loading,error,data,} = useQuery(VENTAS);
  const [ventasCrud] = useMutation(CRUD);
    

  const [state, setState] = useState({
    ventas:[],
    buscar:true,
    openModal:false,
    Productos:null,
    NuevoCupon:{
        "acumulative":false,
        "code": "",
        "discount": "",
        "status": "ACTIVO",
        "type": "PORCENTAJE",
        "typeDiscount": 0,
        "user": "",
        "validTo": "",
      },
    edit:false,
    fechaSelect:'',
    tipoDescuento:null,
    statusSelect:null,
    UsuarioSelect:null
  });

  const columns = [
    {
        name: "clientId",
        label: "Cliente",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let Cliente = state.ventas[dataIndex].clientId;
                return (<div style={{ height: '60px' }}>{Cliente}</div>)
            }
        }
    },
    {
        name: "address",
        label: "Dirección",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let Direccion = state.ventas[dataIndex].address;
                return (<div style={{ height: '60px' }}>{Direccion}</div>)
            }
        }
    },
    {
        name: "",
        label: "Productos",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                //Boton de ojo para los productos
                // let discount = state.ventas[dataIndex].discount;
                return (<div style={{ height: '60px' }}>
                    <IconButton aria-label="Completado" onClick={() => {
                        ModalProductos(state.ventas[dataIndex].products);
                        }}>
                            <RemoveRedEyeIcon color='primary' />
                        </IconButton>
                </div>)
            }
        }
    },
    {
        name: "saleQuantity",
        label: "Cantidad Total",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let cantidad = state.ventas[dataIndex].saleQuantity;
                return (<div style={{ height: '60px'}}>{cantidad}</div>)
            }
        }
    },
    {
        name: "date",
        label: "Fecha de Compra",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let fecha =new Date(parseInt(state.ventas[dataIndex].date));
                return (<div style={{ height: '60px' }}>{`${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`}</div>)
            }
        }
    },
    {
        name: "discount",
        label: "Descuento",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let descuento = state.ventas[dataIndex].discount;
                return (<div style={{ height: '60px' }}>{descuento}</div>)
            }
        }
    },
    {
        name: "total",
        label: "Total",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                const Total = state.ventas[dataIndex].total;
                return (<div style={{ height: '60px' }}>{Total}</div>)
            }
        }
    },
    {
        name: "status",
        label: "Status",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                const status = state.ventas[dataIndex].status;
                return (<div style={{ height: '60px' , fontWeight: 'bolder',color: status === 'COMPLETADO' ? 'green' : status === 'ENVIADO' ? 'blue' : status === 'CANCELADO' ? 'red' : '#e08309'}}>{status}</div>)
            }
        }
    },
    {
        name: "acciones",
        label: "Acciones",
        align: "center",
        options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRenderLite: (dataIndex, i) => (
                <>

                    <div style={{ height: '60px' }} className="d-flex">
                        <IconButton aria-label="Completado" onClick={() => {
                            ChangeStatus("COMPLETADO",state.ventas[dataIndex].id);
                        }}>
                            <VerifiedIcon color='success' />
                        </IconButton>
                        <IconButton aria-label="Cancelado" onClick={() => {
                             ChangeStatus("CANCELADO",state.ventas[dataIndex].id);
                        }}>
                            <DoDisturbIcon color='error' />
                        </IconButton>
                        <IconButton aria-label="Enviado" onClick={() => {
                             ChangeStatus("ENVIADO",state.ventas[dataIndex].id);
                        }}>
                            <LocalShippingIcon color='info' />
                        </IconButton>
                    </div>
                </>
            )
        }
    },
];


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#000000',
    '&:hover': {
        backgroundColor: '#000000',
    },
}));

    
const ModalProductos = (data) =>{
    setState({ ...state, openModal: true, 
        Productos:data,
        edit:true});
  }

  if(loading){return <>Cargando...</>}
  if(error){return <>Ups No hay Sistema...</>}

  if(state.buscar && data){

    setState({
        ...state,
        ventas:data.Sales,
        buscar:false
    });

  }


  const handleCloseModal = () =>{
    setState({ ...state, openModal: false, 
        NuevoCupon:{
            "acumulative":false,
            "code": "",
            "discount": "",
            "status": "ACTIVO",
            "type": "PORCENTAJE",
            "typeDiscount": 0,
            "user": "",
            "validTo": "",
          }, edit:false,
          tipoDescuento:null,
          statusSelect:null,
          UsuarioSelect:null,
          fechaSelect:'',});
  }

  const ChangeStatus = async(status, id) =>{

    
    const { data, loading, error } = await ventasCrud({
        variables: {
            status: status,
            saleStatusId: id
        }
    });
    if(loading){return <>Actualizando...</>}

    if (error) {
        console.log('Algo salio mal -> ', error);
    }

    if (data.SaleStatus) {

        const obj = {}

        const nuevosDatos = state.ventas.map((item)=>{

            if(item.id === data.SaleStatus.id){
                obj.address = item.address
                obj.clientId =item.clientId
                obj.date =item.date
                obj.products = item.products
                obj.id =data.SaleStatus.id
                obj.discount =item.discount
                obj.saleQuantity =item.saleQuantity
                obj.total =item.total
                obj.status = data.SaleStatus.status
            }

            return item.id === data.SaleStatus.id ? obj : item
        })

        setState({...state, ventas: nuevosDatos});
       
        Swal.fire(
            'Status Actualizado!',
            'el Status del pedido fue actualizado con exito',
            'success'
        );
    }
 

  }

  return(
    <>
    <DataTable
                data={state.ventas}
                title={`VENTAS`}
                columns={columns}
    />


<Modal
            open={state.openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
           <Box sx={style}>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sx={{marginBottom:'3%'}} >
                    <h5>{state.NuevoCupon.code !== '' ? state.NuevoCupon.code : 'Productos'}</h5>
                </Grid>
               
                    {/* {
                        state.Productos? 
                        state.Productos[0].funkos.map((item)=>(
                        <Grid item xs={4}>
                            <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Categoria: {item.category}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Nombre: {item.name}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    nomenclature/brand: {item.nomenclature}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Cantidad: {item.quantity}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Precio: {item.price}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Descuento: {item.discount}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Tipo Descuento: {item.typeDiscount}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Variación: {item.variation[0]}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        ))
                        :
                        <>
                        </>
                    } */}
                    {
                        state.Productos? 
                        state.Productos[0].productos.map((item)=>(
                        <Grid item xs={4}>
                            <Card sx={{ minWidth: 275 }}>
                                {console.log(item)}
                                <CardContent>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Categoria: {item.category}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Nombre: {item.name}
                                    </Typography>
                                    {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    nomenclature/brand: {item.brand}
                                    </Typography> */}
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    Cantidad: {item.quantity}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Precio: {item.price}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Descuento: {item.discount}
                                    </Typography>
                                    <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Tipo Descuento: {item.typeDiscount}
                                    </Typography>
                                    {/* <Typography sx={{ mb: 1.5 }} color="text.secondary" gutterBottom>
                                    Variación: {item.variation[0]}
                                    </Typography> */}
                                </CardContent>
                            </Card>
                        </Grid>
                        ))
                        :
                        <>
                        </>
                    }
               

                <Grid align={'right'} item xs={12}>
                    <ColorButton onClick={handleCloseModal}>
                        Salir
                    </ColorButton>
                </Grid>
            </Grid>
            </Box>
        </Modal>
    
    </>)
}




export default Ventas