import React from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import austro from '../../assets/img/austro.avif'
import estrella from '../../assets/img/estrella.avif'
import pagos_seguros from '../../assets/img/pagos_seguros.avif'
import startup from '../../assets/img/startup.avif'

const Informacion = () => {


    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columns={{ xs: 1, sm: 6, md: 12 }}>
                    <Grid align='center' item xs={1} sm={3} md={3}>
                        <Grid container rowSpacing={1} columns={1}>
                        <Grid align='center' item xs={1}>
                            <img src={estrella}/>
                        </Grid>
                        <Grid align='center' item xs={1}>
                                CALIDAD Y DISEÑO
                        </Grid>
                        <Grid align='center' item xs={1}>
                        Innovamos en estilo y diseño en todos nuestros arreglos. Te ofrecemos siempre flores de la mejor calidad.
Ofrecemos un, diseño vanguardista.
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid align='center' item xs={1} sm={3} md={3}>
                        <Grid container rowSpacing={1} columns={1}>
                        <Grid align='center' item xs={1}>
                                <img src={austro}/>
                        </Grid>
                        <Grid align='center' item xs={1}>
                                SOPORTE POR EMAIL 24 HRS
                        </Grid>
                        <Grid align='center' item xs={1}>
                        Sabemos de la importancia de resolver todas tus dudas, es por esto que nuestro email está siempre abierto.
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid align='center' item xs={1} sm={3} md={3}>
                        <Grid container rowSpacing={1} columns={1}>
                        <Grid align='center' item xs={1}>
                            <img src={pagos_seguros}/>
                        </Grid>
                        <Grid align='center' item xs={1}>
                                PAGOS SEGUROS
                        </Grid>
                        <Grid align='center' item xs={1}>
                        Utilizamos métodos confiables y mantenemos tu información totalmente protegida.
                        </Grid>
                        </Grid>
                    </Grid>
                    <Grid align='center' item xs={1} sm={3} md={3}>
                        <Grid container rowSpacing={1} columns={1}>
                        <Grid align='center' item xs={1}>
                            <img src={startup}/>
                        </Grid>
                        <Grid align='center' item xs={1}>
                                SATISFACIÓN O DEVOLUCIÓN    
                        </Grid>
                        <Grid align='center' item xs={1}>
                        Tenemos una política de 100% de satisfacción, si no estas satisfecho con el producto te devolvemos tu dinero.
                        </Grid>
                        </Grid>
                    </Grid>
                   
                </Grid>
            </Box>
        </>
    )
}


export default Informacion;