import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useNavigate } from "react-router-dom";
import ImagenProdctos from "../../assets/img/Productos_blossom.jpeg";
import ImagenClub from "../../assets/img/club_blossom.jpeg";
import Icono from "../../assets/img/new_icon.jpeg";

const CardProductsHome = () => {


    const Item = (img1, img2, title, subTitle, rute) => {

        const history = useNavigate();
        const [img, setImg] = useState(0);
        const onHover = () => {
            setImg(1)
        }

        const offHover = () => {
            setImg(0)
        }

        const handleGoTo = () => {
            history(rute);
        }




        return (
            <>
                <div className='my-3 mx-5'>
                    {
                        img === 1 ?
                            <div
                                className='d-flex flex-column align-items-end justify-content-end cursor-pointer '
                                onClick={handleGoTo}
                                style={{
                                    backgroundImage: `url(${img2})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    width: '100%',
                                    height: "600px"
                                }}
                                onMouseOver={() => onHover()} onMouseOut={() => offHover()}
                            >
                                <div className='w-100 py-3' style={{ background: 'rgba(249, 207, 226,0.7)', color:'#f58bbd', fontWeight: "900" }} >
                                    {title}
                                </div>
                            </div>


                            :
                            <div
                                className='d-flex flex-column align-items-end justify-content-end cursor-pointer'
                                style={{
                                    backgroundImage: `url(${img1})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'contain',
                                    width: '100%',
                                    height: "600px"
                                }}
                                onMouseOver={() => onHover()} onMouseOut={() => offHover()}
                            >
                                {/* <div className='w-100' >
                                    {title}
                                </div> */}
                            </div>

                        // <img 
                        //     src={img1} alt="modulo"
                        //     onMouseOver={() => onHover()} onMouseOut={() => offHover()}
                        //     width="100%" height="600px" 
                        // />
                    }

                </div>
            </>
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                <Grid item xs={6}>
                    {Item(
                        ImagenProdctos,
                        Icono,
                        "Producto sin precio",
                        "",
                        "/Productos")}
                </Grid>
                <Grid item xs={6}>
                    {Item(
                        ImagenClub,
                        Icono,
                        "Subscripción",
                        "",
                        "/Suscripciones")}
                </Grid>
                <Grid item xs={6}>
                    {Item(
                         ImagenClub,
                         Icono,
                        "Blossom Club",
                        "",
                        "/Club-Blossom")}
                </Grid>
                <Grid item xs={6}>
                    {Item(
                        ImagenProdctos,
                        Icono,
                        "Complementos sin precio",
                        "",
                        "/Productos")}
                </Grid>
            </Grid>
        </Box>
    );
}


export default CardProductsHome