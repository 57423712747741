import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SliderAdmin from '../components/Admin/sliderAdmin';
import ProductsBySeason from '../components/Admin/productsBySeason';
import { useQuery, gql } from "@apollo/client";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import DiscountIcon from '@mui/icons-material/Discount';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import Person4Icon from '@mui/icons-material/Person4';
import { useNavigate } from "react-router-dom";
import Charts from '../components/Admin/ChartsAdmin';
import AddProducts from '../components/Admin/AddProductsAdmin';
import Discounts from '../components/Admin/DiscountsAdmin';
import Ventas from '../components/Admin/VentasAdmin';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SocialCongig from '../components/Admin/SocialConfig';

const drawerWidth = 240;


const DATA = gql`
query Query {
    Slider {
    description
    status
    url
    id
  }
  Funkos {
    id
    category
    created
    description
    discount
    imageUrl
    inStock
    name
    nomenclature
    price
    status
    typeDiscount
    variation
    material
  }
  Products {
    id
    
    category
    created
    description
    discount
    imageUrl
    inStock
    name
    color
    price
    status
    typeDiscount
    
  }
  AllSeasonProducts {
    id
    producto {
      description
      name
      id
      inStock
      price
      # material
      # variation
      imageUrl
      # brand
      status
      created
      typeDiscount
      category
      discount
    }
    funko {
      id
      name
      nomenclature
      description
      inStock
      price
      material
      variation
      imageUrl
      category
      status
      created
      typeDiscount
      discount
    }
  }


}
`;


const AdminView = (props) =>{

const navigate = useNavigate();
const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const {loading,error,data,} = useQuery(DATA);

  const [vista, setVista] = useState('');

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {['Productos', 'Otros Productos', 'Slider Principal'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>{
                    setVista(text)
              }}>
              <ListItemIcon>
                {index === 0 ? <Person4Icon /> : index === 2 ? <BurstModeIcon/> : index === 3 ? <BurstModeIcon/> : index === 4 ? <PhotoLibraryIcon/> : <FolderCopyIcon />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Cupones', 'Graficas', 'Ventas', 'Redes Sociales'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>{
                    setVista(text)
              }}>
              <ListItemIcon>
                {index === 0 ? <DiscountIcon /> :  index === 1 ? <QueryStatsIcon/> : index === 2 ?  <AttachMoneyIcon/> : <ConnectWithoutContactIcon/>}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Ir al Sitio'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={()=>{
                    navigate('/')
              }}>
              <ListItemIcon>
                 <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
  const container = window !== undefined ? () => window().document.body : undefined;

    return(
    <>
    {loading ? <></> 
    :

<Box sx={{ display: 'flex' }}>
     
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor:'#000',
          height: 75
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          {/* Titulo header */}
          <Typography variant="h6" noWrap component="div">
            BLOSSOM by M  
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
           container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <div>
          {vista === 'Productos' || vista === ''  ? <AddProducts tipo = 'Producto' data = {data ? data.Products : []}/> : <></>}
          {vista === 'Otros Productos' ? <AddProducts tipo ='Producto' data = {data ?  data.Products : []}/> : <></>}
          {vista === 'Slider Principal' ? <SliderAdmin data={data ? data.Slider : []} /> : <></>}
          {/* {vista === 'De Temporada' ? <ProductsBySeason data={data ? data: [] } /> : <></>} */}
          {vista === 'Graficas' ? <Charts/> : <></>}
          {vista === 'Cupones' ? <Discounts/> : <></>}
          {vista === 'Ventas' ? <Ventas/> : <></>}
          {vista === 'Redes Sociales' ? <SocialCongig/> :  <></>}
        </div>
      </Box>
    </Box>
    }
    
    </>);
}


export default AdminView;