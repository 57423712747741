import React from 'react';


const DescripcionProducto = (data) => {

    const {modelo,descripcion,precio, color} = data;
   
    return (
        <>
            <div className="container-md description-container-product">
                <div className="row">
                    <div className="col">
                        <div className="titledescrip">
                            Descripcion
                        </div>
                        <div>
                            <p>{descripcion}</p>
                        </div></div>
                    <div className="col">
                        <div className="titledescrip">
                            Parametros
                        </div>
                        <div className="contenedor-barr">
                            <p>Rareza</p>
                            <div className="progress-barr viento stripes shine">
                                <span style={{width: '90%'}}></span>
                            </div>
                        </div>
                        <div className="contenedor-barr">
                            <p>Diseño</p>
                            <div className="progress-barr frio stripes shine">
                                <span style={{width:"66%"}}></span>
                            </div>
                        </div>
                        <div className="contenedor-barr">
                            <p>Colección</p>
                            <div className="progress-barr sol stripes shine">
                                <span style={{width: "66%"}}></span>
                            </div>
                        </div>
                    </div></div>
                <div className="row">
                    <div className="col">
                        <div className="titledescrip">
                            Caracteristicas
                        </div>
                        <div className="ficha-tecnica">
                            <ul>
                                <li>
                                    <p>Color:  {color.map(item=>(<b>{item}</b>))},</p>
                                </li>
                                {/* <li>
                                    <p>Numero: <b>{modelo}</b></p>
                                </li> */}
                                <li>
                                    <p>Medida Aprox: <b>24 x 47 cm</b></p>
                                </li>
                                {/* <li>
                                    <p>Material: <b>PVC</b></p>
                                </li> */}
                                {/* <li>
                                    <p>Temporada: <b>Primavera</b></p>
                                </li> */}
                                {/* <li>
                                    <p>Colección: <b>{coleccion[0]}</b></p>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col" id="cel-cambio">
                        <div className="titledescrip">
                            Ficha tecnica del Arreglo
                        </div>
                        <div className="ficha-tecnica">
                            <ul>
                                <li>
                                    <p>Modelo: <b>{modelo}</b></p>
                                </li>
                                <li>
                                    <p>Lanzamiento: <b>Mayo 2023</b></p>
                                </li>
                                <li>
                                    <p>Categoria: <b>{modelo}</b></p>
                                </li>
                                <li>
                                    <p>Precio: <b>${precio}</b></p>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="titledescrip">
                            Datos tecnicos
                        </div> */}
                        {/* <div className="ficha-tecnica">
                            <ul>
                                <li>
                                    <p>Color:  <b>Negro, Azul, Verde, Morado</b></p>
                                </li>
                                <li>
                                    <p>Numero: <b>45</b></p>
                                </li>
                                <li>
                                    <p>Medida Aprox: <b>24 x 47 cm</b></p>
                                </li>
                                <li>
                                    <p>Material: <b>PVC</b></p>
                                </li>
                                <li>
                                    <p>Temporada: <b>Primavera</b></p>
                                </li>
                                <li>
                                    <p>Coleccion: <b>Artistas Famosos</b></p>
                                </li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
            </div>
        </>
    );
}




export default DescripcionProducto;