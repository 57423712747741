import React from 'react'
import UploadImages from '../components/Admin/UploadImages';
import SectionWipes2 from '../components/Shop/ScrollmagicComponent';

 const Test = () =>{



    return(
    <div>
        <SectionWipes2/>
    </div>);
}



export default Test;



