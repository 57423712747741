import React from "react";


const PagoValido = () => {

    return (
    <>
    <p>Muchas Gracias por tu pago :v estamos trabajando en esta vista usted disculpe XD </p>
    </>)


}


export default PagoValido