import React from 'react';
import PagoValido from '../components/Shop/paypal/PagoExitoso';

const UserView = () =>{


    return(<>
    <div style={{minHeight:"200px"}}>
    </div>
    <PagoValido/>
    
    </>);

}




export default UserView;