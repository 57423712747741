import React from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from "react-router-dom";


const Paypal = (props) =>{

    let navigation = useNavigate();

    const {descripcion, precio, cantidad, } = props;
    return(<>
        <PayPalButtons
        createOrder={(data, actions)=>{
            return actions.order.create({
                purchase_units:[
                   { 
                    description:descripcion,
                    amount:{
                        value:precio,

                    },

                }
                ]
            })
        }}
        onApprove={async (data, actions)=>{
            const orden = await actions.order.capture()

            console.log("data-> paypal", orden);

            if(orden.status === "COMPLETED"){
                navigation("/usuario");
            }

        }}
        />
    </>)
}




export default Paypal