import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useMutation, gql, useQuery } from "@apollo/client";
import Swal from 'sweetalert2'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select';


const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor:'#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
  }));



const CRUDSEASONPRODUCTS = gql`
  mutation Mutation($season: SeasonInput!, $opcion: String!) {
    SeasonCrud(season: $season, opcion: $opcion) {
        id
        producto {
            description
            name
            id
            inStock
            price
            color
            flor
            imageUrl
            # brand
            status
            created
            typeDiscount
            category
            discount
        }
        funko {
            id
            name
            # nomenclature
            description
            inStock
            price
            # material
            # variation
            imageUrl
            category
            status
            created
            typeDiscount
            discount
        }
    }
  }
`;


const ProductsBySeason = ({data}) => {

    console.log("MY SEASON DATA", data)

    const [crudSeasonProducts] = useMutation(CRUDSEASONPRODUCTS,
        {
        context: {
          headers: {
            'apollo-require-preflight': true,
          },
    }});

    const [state, setState] = useState({
        data: [],
        productos:[],
        funkos:[],
        modalOpen:false,
        nombre:'',
        descripcion:'',
        imagenName:null,
        descripcionerror:false,
        imgError:false,
        img:null,
        selectedProduct: null
    });

    const Eliminar = async (id) =>{
        console.log("SEASON ID", id)
        
            const {data:response, loading, error} = await crudSeasonProducts({
                variables: {
                    season:{
                        "funko": id
                    },
                    opcion: "Delete"
                }  
            });
            
            const array = state.data.filter((item) => item.id !== id )
            console.log("ARRAY ELIMINADO YA", array)
            setState({...state, data:array });

            Swal.fire('Listo!', 'El producto se eliminó correctamente de los productos de temporada.', 'success')
            
        
    }

    const EliminarSweet = (id) =>{
        console.log("MY id", id);
        Swal.fire({
            title: 'De verdad quieres eliminar este producto de los productos de temporada?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Si!',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              
              Eliminar(id);
                
            } else if (result.isDenied) {
                Swal.fire('La operación fue cancelada', '', 'info')
            }   
          })
    }
    

    const addProduct = async () =>{
        console.log("Agregando Producto")
        if(state.selectedProduct){
            console.log("My product", state.selectedProduct[0])
            try{
                const {data:response, loading, error} = await  crudSeasonProducts({
                    variables: {
                        season:{
                            "funko": state.selectedProduct[0].value
                        },
                        opcion: "Add"
                    }  
                });

                console.log("response",response);
        
                if(response){
                
                    console.log("RESPONSE", response);
                    const array = state.data.filter((item) => item.id !== response.SeasonCrud.id )
                    array.unshift(response.SeasonCrud);
                    setState({...state,
                        data:array, 
                        modalOpen:false,
                        nombreError:false,
                        nombre:'',
                        descripcion:'',
                        imagenName:null,
                        img:null });
            
                    Swal.fire(
                        'Agregado!',
                        'Se agregó correctamente el producto de temporada!!',
                        'success'
                    )

                  
                }
            }catch(e){
                console.log('Error->', e);
                if(e.message == 'Este Producto ya esta dentro de los Productos de temporada!'){
                    Swal.fire(
                        'Alerta!',
                        e.message,
                        'warning'
                    )
                }else{
                    Swal.fire(
                        'Ha ocurrido un error!',
                        'Contacta al administrador del sitio',
                        'error'
                    )
                }
            }
           

        }else{
            Swal.fire({
                icon: 'error',
                title: ':c ',
                text: 'Por favor llena todos los campos :D !',
              })
        }
    }
    
    

    const handleClose =() =>{
        setState({...state,
            modalOpen:false,
            nombreError:false,
            nombre:'',
            descripcion:'',
            imagenName:null,
            img:null 
        });
    }
    
    const openmodal = () => {
        setState({...state, modalOpen:true,});
    }


    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            
            setState({...state, productos: data.Products ? [...data.Products] : [], funkos: data.Funkos ? [...data.Funkos] : [], data: data.AllSeasonProducts ? [...data.AllSeasonProducts] : []});
        }

        return () => {
            isMounted = false
        }
    }, [])

    
    let OptionsProductos = [];
    
    state.productos.map((item) =>(
        OptionsProductos.push({value:item.id, label:item.name})
    ))
    state.funkos.map((item) =>(
        OptionsProductos.push({value:item.id, label:item.name})
    ))

    console.log("optinos", OptionsProductos);

    const handleChangeProductosSelect = (e)=>{
        console.log("asdfsdfsa", e);
        setState({...state,selectedProduct:e});
        // console.log("selected", state.selectedProduct.value)
    }

    return (
        <>
        <div >
        <div className='container'>
                <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="right" item xs={12} sm={6} md={6}>
                        <ColorButton  startIcon={<AddPhotoAlternateIcon />} onClick={openmodal}>
                            Agregar Producto de Temporada
                        </ColorButton>
                    </Grid>
                </Grid>
        </div>

        <Box sx={{ flexGrow: 1, marginLeft: '3%', marginBottom:'2%', marginTop:'5%' }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {state.data.length > 0 ? 
                <>
                {state.data.map((item) =>(
                    item.producto.id === null ?
                        <Grid item xs={2} sm={4} md={4} key={item.funko.id}>
                        <Card sx={{ maxWidth: 345 }} key={item.funko.id} >
                            <CardMedia
                                component="img"
                                alt="Imagen 1"
                                height="100%"
                                image={item.funko.imageUrl[0]}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                <b>{item.funko.name}</b>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    
                                Eliminar: *para eliminar este producto de los productos de temporada da click en el botón de abajo.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() =>{EliminarSweet(item.id)}} size="small">Eliminar</Button>
                            </CardActions>
                            </Card>
                        </Grid>
                    :
                        <Grid item xs={2} sm={4} md={4} key={item.producto.id}>
                        <Card sx={{ maxWidth: 345 }} key={item.producto.id} >
                            <CardMedia
                                component="img"
                                alt="Imagen 1"
                                height="100%"
                                image={item.producto.imageUrl[0]}
                            />
                            <CardContent>
                                <Typography variant="body2" color="text.secondary">
                                <b>{item.producto.name}</b>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    
                                Eliminar: *para eliminar este producto de los productos de temporada da click en el botón de abajo.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button onClick={() =>{EliminarSweet(item.id)}} size="small">Eliminar</Button>
                            </CardActions>
                            </Card>
                        </Grid>
                ))}
                </>
                : 
                <>
                    <hr></hr>
                    <h1>No hay ningún producto de temporada</h1>
                    <hr></hr>
                </>
                }
                
            </Grid>
        </Box>


        <Modal
                open={state.modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid container spacing={2} sx= {{ justifyContent: 'center', alignItems: 'center', marginTop:'5%' }}>
                        <Grid itme xs={12}>
                        <Box sx={{ minWidth: '100%' }}>
                            <FormControl fullWidth>
                                <Select
                                value={state.selectedProduct ? state.selectedProduct : '' }
                                name="Productos"
                                isMulti
                                className="basic-multi-select"
                                classNamePrefix="select-productos"
                                isSearchable={true}
                                placeholder = "Productos"
                                closeMenuOnSelect={true}
                                options={OptionsProductos}
                                onChange={options => handleChangeProductosSelect(options)}
                            />
                            </FormControl>
                        </Box>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={2} sx= {{ justifyContent: 'center', alignItems: 'center', paddingTop:'4%'}}>
                        <Grid item xs="auto">
                                <ColorButton variant="outlined" 
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose}>
                                Cancelar
                                </ColorButton>
                        </Grid>
                        <Grid item xs={5}></Grid>
                        
                        <Grid item xs="auto">
                                <ColorButton variant="outlined" 
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => addProduct()}>
                                Guardar
                                </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
        </Modal>
        </div>
        </> );
}

export default ProductsBySeason;