import React from 'react'
import imagen404 from '../assets/img/404notfound.png'



const NotFound = () =>{


    return(
    <div className="NotFound404">

        <img src={imagen404}/>
    </div>
    );
}





export default NotFound;