import React, { useState, useEffect } from 'react'
import DataTable from '../DataTableComponents';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { useMutation, gql, useQuery } from "@apollo/client";
import Swal from 'sweetalert2'
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const ELIMINAR = gql`
mutation Eliminar($deleteFunkoId: String, $tipo: String) {
  DeleteFunko(id: $deleteFunkoId, tipo: $tipo)
}
`;

const ACTIVAR = gql`

mutation Activar($activarProductosId: String, $status: Boolean) {
  ActivarProductos(id: $activarProductosId, status: $status)
}

`;


const ADDFUNKO = gql`
mutation AddFunko($funko: FunkoInput!, $opcion: String!) {
  FunkoCrud(funko: $funko, opcion: $opcion) {
    category
    created
    description
    discount
    id
    imageUrl
    material
    inStock
    name
    nomenclature
    price
    status
    variation
    typeDiscount
  }
}
`;

const ADDPRODUCT = gql`
mutation Mutation($product: ProductInput!, $opcion: String!) {
    ProductCrud(product: $product, opcion: $opcion) {
      category
      created
      description
      discount
      id
      imageUrl
      inStock
      flor
      color
      name
      price
      status
      typeDiscount
    #   variation
    }
  }
`;



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius:'9px',
    p: 4,
    display: "flex",
    flexDirection: "column",
    height: 550,
    overflow: "hidden",
    overflowY: "scroll",
  };


const AddProducts = ({ tipo, data }) => {


    const [state, setState] = useState({
        productos: [],
        edit:false,
        openModal: false,
        nuevoProducto:{
            category:'',
            description:'',
            discount:'',
            imageUrl:[],
            inStock:'',
            name:'',
            nomenclature:'',
            price:'',
            status:true,
            typeDiscount:'PORCENTAJE',
            // variation:'',
            flor:'',
            color:[],
            presale:false
        },
        imagenesAdd:[]
    });
    const [EliminarProducto] = useMutation(ELIMINAR);
    const [ActivarProducto] = useMutation(ACTIVAR);
    const [addFunko] = useMutation(ADDFUNKO);
    const [addProduct] = useMutation(ADDPRODUCT);


    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
        },
    }));

    let nombra = tipo === 'Funko' ? 'nomenclature'  : 'brand';

    const columns = [
        {
            name: "name",
            label: "Nombre",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.productos[dataIndex].name;
                    return (<div style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: nombra,
            label: "Flor",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.productos[dataIndex].flor ? state.productos[dataIndex].flor : state.productos[dataIndex].flor;
                    return (<div style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "price",
            label: "Precio",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.productos[dataIndex].price;
                    return (<div style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "inStock",
            label: "Stock",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.productos[dataIndex].inStock;
                    return (<div style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "category",
            label: "Categoria",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let nombre = state.productos[dataIndex].category;
                    return (<div style={{ height: '60px' }}>{nombre}</div>)
                }
            }
        },
        {
            name: "status",
            label: "Estatus",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    let estatus = state.productos[dataIndex].status;
                    return (<div style={{ height: '60px' }}>{estatus ? 'Activo en Web' : 'Inactivo en la web'}</div>)
                }
            }
        },
        {
            name: "created",
            label: "Creado",
            options: {
                filter: true,
                sort: false,
                customBodyRenderLite: (dataIndex, i) => {
                    const fecha = new Date(parseInt(state.productos[dataIndex].created));
                    return (<div style={{ height: '60px' }}>{`${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`}</div>)
                }
            }
        },
        {
            name: "acciones",
            label: "Acciones",
            align: "center",
            options: {
                filter: false,
                sort: false,
                download: false,
                customBodyRenderLite: (dataIndex, i) => (
                    <>

                        <div style={{ height: '60px' }} className="d-flex">
                            <IconButton aria-label="edit" onClick={() => {
                                ModalEditar(state.productos[dataIndex])
                            }}>
                                <EditIcon color='primary' />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => {
                                MensajeEliminar(state.productos[dataIndex].id)
                            }}>
                                <DeleteForeverIcon color='secondary' />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => {
                                ActivarEnWeb(state.productos[dataIndex].id, state.productos[dataIndex].status)
                            }}>
                                {state.productos[dataIndex].status ? <VisibilityOffIcon /> : <VisibilityIcon />}
                            </IconButton>
                        </div>
                    </>
                )
            }
        },
    ];

    const handleCloseModal = () => {
        setState({ ...state, openModal: false, 
            nuevoProducto:{
                category:'',
                description:'',
                discount:'',
                imageUrl:[],
                inStock:'',
                name:'',
                nomenclature:'',
                price:'',
                status:true,
                typeDiscount:'PORCENTAJE',
                // variation:'',
                flor:'',
                color:[],
                presale:false
            }, edit:false, imagenesAdd:[]});
    }

    const ModalEditar = (item) => {
        setState({ ...state, openModal: true, nuevoProducto: JSON.parse(JSON.stringify(item)) , edit:true });
    }

    const editar = async () =>{

        let id =state.nuevoProducto.id;
        if(tipo === 'Funko'){

            delete state.nuevoProducto.id;
            delete state.nuevoProducto.brand;
            delete state.nuevoProducto.__typename;
            delete state.nuevoProducto.created;
            state.nuevoProducto.price = parseFloat(state.nuevoProducto.price);
            if(!( typeof state.nuevoProducto.variation === 'object')){
                state.nuevoProducto.variation = [state.nuevoProducto.variation];
            }
            if(state.imagenesAdd.length > 1 ){
                state.nuevoProducto.imageUrl = state.imagenesAdd;

                const { data, loading, error } = await addFunko({
                    variables: {
                        funko: state.nuevoProducto,
                        opcion: "UpdateWithImg"
                    }
                });
        
        
                if(error){
        
                    console.log('ups -> ',error);
                    handleCloseModal();
        
                }
        
                if(data){
                    const array = state.productos.filter((item) => item.id !== id )
                    array.unshift(data.FunkoCrud);
                    setState({...state,productos:array});
                    handleCloseModal();
        
                }
            }else{

                const { data, loading, error } = await addFunko({
                    variables: {
                        funko: state.nuevoProducto,
                        opcion: "Update"
                    }
                });
        
        
                if(error){
        
                    console.log('ups -> ',error);
                    handleCloseModal();
        
                }
        
                if(data){
                    
                    const array = state.productos.filter((item) => item.id !== id )
                    array.unshift(data.FunkoCrud);
                    setState({...state,productos:array});
                    handleCloseModal();
        
                }
            }


        }else{
            delete state.nuevoProducto.id;
            delete state.nuevoProducto.nomenclature;
            delete state.nuevoProducto.__typename;
            delete state.nuevoProducto.created;
            state.nuevoProducto.price = parseFloat(state.nuevoProducto.price);
            // if(!( typeof state.nuevoProducto.variation === 'object')){
            //     state.nuevoProducto.variation = [state.nuevoProducto.variation];
            // }
            if(state.imagenesAdd.length > 1 ){
                state.nuevoProducto.imageUrl = state.imagenesAdd;

                const { data, loading, error } = await addProduct({
                    variables: {
                        product: state.nuevoProducto,
                        opcion: "UpdateWithImg"
                    }
                });
        
        
                if(error){
        
                    console.log('ups -> ',error);
                    handleCloseModal();
        
                }
        
                if(data){
                    const array = state.productos.filter((item) => item.id !== id )
                    array.unshift(data.ProductCrud);
                    setState({...state,productos:array});
                    handleCloseModal();
        
                }
            }else{

                const { data, loading, error } = await addProduct({
                    variables: {
                        product: state.nuevoProducto,
                        opcion: "Update"
                    }
                });
        
        
                if(error){
        
                    console.log('ups -> ',error);
                    handleCloseModal();
        
                }
        
                if(data){
                    
                    const array = state.productos.filter((item) => item.id !== id )
                    array.unshift(data.ProductCrud);
                    setState({...state,productos:array});
                    handleCloseModal();
        
                }
            }
            console.log(state.nuevoProducto)
        }
    }

    const AddaDB = async () =>{
    
        setState({...state, openModal:false});

        state.nuevoProducto.imageUrl = state.imagenesAdd;
        state.nuevoProducto.price = parseFloat(state.nuevoProducto.price);
        // state.nuevoProducto.variation = [state.nuevoProducto.variation];


        if(tipo === 'Funko'){
            delete state.nuevoProducto.brand;

            const { data, loading, error } = await addFunko({
                variables: {
                    funko: state.nuevoProducto,
                    opcion: "Add"
                }
            });
    
    
            if(error){
    
                console.log('ups -> ',error);
                handleCloseModal();
    
            }
    
            if(data){
                console.log(data);
                state.productos.unshift(data.FunkoCrud);
                handleCloseModal();
    
            }

        }else{
            delete state.nuevoProducto.nomenclature;
            delete state.nuevoProducto.presale;
           
            const { data , loading, error} = await addProduct({
                variables: {
                    product: state.nuevoProducto,
                    opcion: "Add"
                }
            });

            if(error){
    
                console.log('ups -> ',error);
                handleCloseModal();
    
            }
    
            if(data){
                console.log(data);
                state.productos.unshift(data.ProductCrud);
                handleCloseModal();
    
            }
        }

    }


    const MensajeEliminar = (item) => {
        Swal.fire({
            title: 'Estas seguro de eliminarlo?',
            text: "Si eliminas este producto lo borraras de la base de datos :c si lo que quieres es quitarlo de la web unicamente, prueba con la opcion de habilitar o deshabilitar (el boton del ojo)",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Eliminalo!',
            cancelButtonText: 'No, Cancelar'
        }).then((result) => {
            if (result.isConfirmed) {
                Eliminar(item)
            }
        })
    }
    const Eliminar = async (id) => {

        const { data, loading, error } = await EliminarProducto({
            variables: {
                deleteFunkoId: id,
                tipo: tipo
            }
        });

        if (error) {
            console.log('Algo salio mal -> ', error);
        }

        if (data.DeleteFunko === 'success') {
            const array = state.productos.filter((item) => item.id !== id)
            setState({ ...state, productos: array });
            Swal.fire(
                'Eliminado!',
                'el producto fue eliminado de tu base de datos :c.',
                'success'
            );
        }

    }
    const ActivarEnWeb = async (id, status) => {


        const { data, loading, error } = await ActivarProducto({
            variables: {
                activarProductosId: id,
                status: !status
            }
        });

        if (error) {
            console.log('algo Salio Mal', error)
        }

        if (data.ActivarProductos) {

            const array = state.productos.map((item) => {
                if (item.id === id) {
                    const obj = {
                        id: item.id,
                        category: item.category,
                        created: item.created,
                        description: item.description,
                        discount: item.discount,
                        imageUrl: item.imageUrl,
                        inStock: item.inStock,
                        name: item.name,
                        flor: item.flor,
                        price: item.price,
                        status: !status,
                        typeDiscount: item.typeDiscount,
                        // variation: item.variation,
                        color: item.color[0]
                    }
                    return obj;
                } else {
                    return item;
                }
            })



            setState({ ...state, productos: array });

            if (!status) {
                Swal.fire(
                    'Producto mostrado en la web!',
                    'El Producto ahora es visible en la web!!',
                    'success'
                );
            } else {
                Swal.fire(
                    'Producto ocultado en la web!',
                    'El Producto ya no es visible en la web!!',
                    'success'
                );
            }


        }
        else {
            Swal.fire(
                'Oops',
                'Algo salio mal, intente mas tarde',
                'error'
            );
        }


    }


    const handleChangeStatusSelect = (e) =>{

        setState({...state,nuevoProducto:{...state.nuevoProducto,status: e.target.value}});
    }

    const handleChangeDescuentoSelect = (e) => {
        setState({...state,nuevoProducto:{...state.nuevoProducto,typeDiscount: e.target.value}});
    }

    const guardarimagenes = (e) =>{
        const img = e.target.files;
        const fotos = [];
        const imagenesADD = [];

        for (let index = 0; index < img.length; index++) {
            const element = img[index];
            const URLImagenEdit = URL.createObjectURL(element);
            fotos.push(URLImagenEdit);
            const reader = new FileReader();

            reader.onloadend = () => {
                // Use a regex to remove data url part
                const base64String = reader.result
                    .replace('data:', '')
                    .replace(/^.+,/, '');
                    imagenesADD.push( base64String);
            };
            reader.readAsDataURL(element);
        }
        // const URLImagenEdit = URL.createObjectURL(foto);
        setState({...state,imagenesAdd:imagenesADD, nuevoProducto:{...state.nuevoProducto,imageUrl:fotos}});

    }

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setState({ ...state, productos: [...data] });
        }
        return () => {
            isMounted = false
        }
    }, [])

    return (<>

        <div >
            <Grid sx={{ marginTop: 3 }} container spacing={2}>
                <Grid align="center" item xs={12} sm={6} md={6}>
                </Grid>
                <Grid align="right" item xs={12} sm={6} md={6}>
                    <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() => { setState({ ...state, openModal: true }) }}>
                        Nuevo {tipo}
                    </ColorButton>
                </Grid>
            </Grid>
        </div>

        <DataTable
            data={state.productos}
            title={`Tabla de ${tipo}`}
            columns={columns}
        />

        <br/>
        <br/>



        <Modal
            open={state.openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
           <Box sx={style}>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sx={{marginBottom:'3%'}} >
                    <h5>{state.nuevoProducto.name !== '' ? state.nuevoProducto.name : 'Nuevo Producto'}</h5>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                    id="outlined-basic" 
                    label="Nombre/Titulo"
                    variant="outlined"
                    value={state.nuevoProducto.name}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,name: e.target.value}});}}
                    />
                </Grid>
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Categoria"
                    variant="outlined"
                    value={state.nuevoProducto.category}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,category: e.target.value}});}}
                    />
                </Grid>
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Stock"
                    variant="outlined"
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,inStock: parseInt(e.target.value)}});}}
                    value={ state.nuevoProducto.inStock}/>
                </Grid>
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Precio"
                    variant="outlined"
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,price: e.target.value}});}}
                    value={ state.nuevoProducto.price}/>
                </Grid>
                {tipo === 'Funko' ? 
                <Grid item xs={3}>
                    <TextField 
                    id="outlined-basic" 
                    label="Nomenclature"
                    variant="outlined"
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,nomenclature: e.target.value}});}}
                    value={ state.nuevoProducto.nomenclature}/>
                </Grid>
                :
                <Grid item xs={3}>
                    <TextField 
                    id="outlined-basic" 
                    label="Flor"
                    variant="outlined"
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,flor: e.target.value}});}}
                    value={ state.nuevoProducto.flor}/>
                </Grid>
                }
                {/* <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Variation"
                    variant="outlined"
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,variation: e.target.value}});}}
                    value={state.nuevoProducto.variation}/>
                </Grid> */}
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Color"
                    variant="outlined"
                    value={state.nuevoProducto.color[0] ?? ""}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,color: [e.target.value]}});}}
                    />

                </Grid>
                <Grid item xs={3}>
                <Select
                labelId="statusSelect"
                id="statusSelect"
                value={state.nuevoProducto.status}
                label="Status"
                onChange={handleChangeStatusSelect}
                >
                <MenuItem value={true}>Mostrar en la web</MenuItem>
                <MenuItem value={false}>Ocultar en la web</MenuItem>
                </Select>
                </Grid>
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Descuento"
                    variant="outlined"
                    value={state.nuevoProducto.discount}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,discount: parseInt(e.target.value)}});}}
                    />

                </Grid>
                <Grid item xs={3}>
                <Select
                labelId="tipoDescuentoSelect"
                id="tipoDescuentoSelect"
                value={ state.nuevoProducto.typeDiscount}
                label="Tipo Descuento"
                onChange={handleChangeDescuentoSelect}
                >
                <MenuItem value={'PORCENTAJE'}>Por Porcentaje</MenuItem>
                <MenuItem value={'NUMERICO'}>Descuento Numerico</MenuItem>
                </Select>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" component="label" sx={{ marginBottom: '25%' }}>
                    <AddPhotoAlternateIcon />

                    <input type="file" multiple hidden onChange={guardarimagenes} accept='.png , .jpg , .webp' />
                  </Button>
                  </Grid>
                <Grid item xs={12} sx={{marginBottom:'3%'}} >
                <TextField
                    label="Descripción"
                    fullWidth
                    id="desc"
                    variant="outlined"
                    type='text'
                    multiline
                    rows={5}
                    rowsmax={10}
                    value={state.nuevoProducto.description}
                    onChange={(e) =>{setState({...state,nuevoProducto:{...state.nuevoProducto,description: e.target.value}});}}
                    /> 
                </Grid>
                {state.nuevoProducto.imageUrl.map((item)=>(
                    <Grid item xs={3}>
                    <img className="img--news" style={{ width: '100%', height:'216px' }} src={item} alt={''}></img>
                    </Grid>
                ))}
                <Grid align={'right'} item xs={12}>
                    <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() =>{ state.edit ?  editar() : AddaDB()}}>
                        Guardar
                    </ColorButton>
                </Grid>
            </Grid>
            </Box>
        </Modal>


    </>);
}




export default AddProducts