import React, { useContext, useState } from 'react'
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { CardActionArea } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import Divider from '@mui/material/Divider';
import ToysContext from '../context/ToysContext';
// import Select from 'react-select';
import { useQuery, gql } from "@apollo/client";
import Swal from 'sweetalert2'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import Slider from '@mui/material/Slider';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import ListItemText from '@mui/material/ListItemText';


import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === '#ffffff' ? '#ffffff' : '#ffffff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: '#000',
  width: "230px"
}));

const ColorButton = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#ffffff',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
}));

const ColorButtonNoStock = styled(Button)(({ theme }) => ({
  color: '#000',
  backgroundColor: '#6f6f6f',
  '&:hover': {
    backgroundColor: '#6f6f6f',
  },
}));


const BUSCAR = gql`
  query Query {
  Funkos {
    category
    description
    discount
    created
    id
    imageUrl
    inStock
    name
    material
    nomenclature
    price
    status
    typeDiscount
    
  }

  Products {
    # brand
    category
    created
    description
    discount
    id
    imageUrl
    inStock
    flor
    color
    name
    price
    status
    typeDiscount
    # variation
  }

}
  `;



const ProductosView = () => {

  const { loading, error, data, } = useQuery(BUSCAR);
  let { titulo } = useParams();
  const navigate = useNavigate();
  const { addcar } = useContext(ToysContext);

  const [personName, setPersonName] = useState([]);
  const [ocacionName, setOcacionName] = useState([]);


  const [value, setValue] = useState([100, 1000]);
  const [valoresLabelSlider, setLabelValue] = useState({ inicial: 100, final: 1000 })

  const [state, setState] = useState({
    filtroUno: null,
    filtroDos: [],
    productos: [],
    buscar: true,
    productosBusqueda: []
  });



  const goToProduct = (product) => {
    navigate(`/producto/${product}`);
  }

  const handleAddToCar = (product) => {
    console.log("MI FUNKO", product)
    addcar(product)
    Swal.fire('Listo!', 'El producto se agregó correctamente al carrito!.', 'success')
  }





  const handleChangeBuscadorNombre = (e) => {

    const objBuscar = [];



    if (titulo !== 'Funkos') {
      state.productos.Products.map((product) => {
        if (product.name.toLowerCase().includes(e.toLowerCase())) {
          objBuscar.push(product)
        }
      })
    }
    state.productos.Funkos.map((product) => {

      if (product.name.toLowerCase().includes(e.toLowerCase())) {
        objBuscar.push(product)
      }
    })


    setState({ ...state, productosBusqueda: objBuscar });
  }





  const handleChange = (event, newValue) => {
    setValue(newValue);

    setLabelValue({ inicial: newValue[0], final: newValue[1] })


  };


  const handleChangeSelectFlor = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const handleChangeSelectOcacion = (event) => {
    const {
      target: { value },
    } = event;
    setOcacionName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };



  if (loading) { <>Cargando....</> }
  if (error) { <>Ups....</> }
  if (data && state.buscar) {

    let filtro = [];
    let productosFiltro = []
    switch (titulo) {
      case 'HotWheels':
        filtro.push({ value: 'Hotwheels', label: 'Hotwheels' })
        data.Products.map((product) => {

          if (product.brand === titulo) {
            productosFiltro.push(product)
          }
        }
        )
        break;

      case 'pre-ventas':
    };

    setState({ ...state, productos: data, buscar: false, filtroDos: filtro, productosBusqueda: productosFiltro });

  }


  const marks = [
    {
      value: 100,
      label: '$100',
    },

    {
      value: 10000,
      label: '$10,000',
    },
  ];


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };


  const names = [
    "Flor del cerezo",
    "hortensia",
    "lirio",
    "orquídea",
    "peonía",
    "rosa",
    "dalia",
    "nardo",
    "magnolia",
    "campanilla",
    "gardenia",
    "crisantemo",
    "flor de loto",
    "azalea"
  ];

  const namesOcacion = [
    "Boda",
    "Cumpleaños",
    "Aniversario",
    "Bautismo",
    "14 Febrero",

  ]

  return (
    <>
      {data ? <>


        <Divider />




        <Box sx={{ flexGrow: 1, marginTop: '10%', marginLeft: '4%', marginRight: '4%', marginBottom: '5%' }}>

          <Grid container spacing={2} columns={16}>
            <Grid item xs={3}>


              <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                <nav aria-label="main mailbox folders">
                  <List>
                    <ListItem disablePadding>

                      <Box sx={{ width: 300 }}>
                        <ListItemText primary={`PRECIO DE: $${valoresLabelSlider.inicial} A $${valoresLabelSlider.final}`} />

                        <Slider
                          // getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          //getAriaValueText={valuetext}
                          marks={marks}
                          min={100} max={10000}
                        />
                      </Box>
                    </ListItem>
                    <ListItem disablePadding>
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">FLOR</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleChangeSelectFlor}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={personName.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>
                  </List>
                </nav>
                <Divider />
                <nav aria-label="main mailbox folders">
                  <List>
                    <ListItem disablePadding>
                      {/* <ListItemText primary="OCASIÓN" /> */}
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">OCASIÓN</InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={ocacionName}
                          onChange={handleChangeSelectOcacion}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(', ')}
                          MenuProps={MenuProps}
                        >
                          {namesOcacion.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={ocacionName.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </ListItem>

                  </List>
                </nav>

              </Box>


            </Grid>
            <Grid item xs={12} sx={{ marginLeft: '2%' }}>

              <Grid container spacing={{ xs: 10, md: 32 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                {state.productosBusqueda.length > 0 ?

                  state.productosBusqueda.map((item, index) => (
                    <Grid item xs={2} sm={2} md={2} key={index}>
                      <Item>
                        <CardActionArea onClick={() => { goToProduct(item.name) }}>
                          <img src={item.imageUrl[0]} style={{ width: '200px', height: '250px' }} />
                        </CardActionArea>
                        <p>{item.name}</p>
                        {/* <h6>{item.variation[0]}</h6> */}
                        <p className='cardproductosstyle'>${item.price}</p>
                        {item.inStock < 1 ?
                          <ColorButtonNoStock variant="contained">No Disponible</ColorButtonNoStock>
                          :
                          <ColorButton onClick={() => {
                            handleAddToCar({
                              "imagen": item.imageUrl[0],
                              "name": item.name,
                              "price": item.price,
                              // "variation": item.variation[0],
                              "id": item.id,
                              "cantidad": 1,
                              "inStock": item.inStock
                            })
                          }} variant="contained">AÑADIR AL CARRITO</ColorButton>}

                      </Item>
                    </Grid>
                  ))
                  : state.productosBusqueda.length === 0 && state.filtroDos.length > 0 ? <p className='BoxProductos'>:C no Tenemos por el momento productos </p> : data.Products.map((item, index) => (
                    <Grid item xs={2} sm={2} md={2} key={index}>
                      <Item>
                        <CardActionArea onClick={() => { goToProduct(item.name) }}>
                          <img src={item.imageUrl[0]} style={{ width: '200px', height: '250px' }} />
                        </CardActionArea>
                        <p>{item.name}</p>
                        {/* <h6>{item.variation[0]}</h6> */}
                        <p className='cardproductosstyle'>${item.price}</p>
                        {item.inStock < 1 ?
                          <ColorButtonNoStock variant="contained">No Disponible</ColorButtonNoStock>
                          :
                          <ColorButton onClick={() => {
                            handleAddToCar({
                              "imagen": item.imageUrl[0],
                              "name": item.name,
                              "price": item.price,
                              // "variation": item.variation[0],
                              "id": item.id,
                              "cantidad": 1,
                              "inStock": item.inStock
                            })
                          }} variant="contained">AÑADIR AL CARRITO</ColorButton>}

                      </Item>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Grid>

        </Box>


      </> : <>NO HAY RESULTADOS</>}
    </>
  );
}


export default ProductosView;






const codigoViejo = () => {


  // const handleChangeCategoriasSelect = (e)=>{


  //         const objBuscar = [];

  //         e.map((item)=>{

  //             if(titulo !== 'Funkos'){
  //                 state.productos.Products.map((product) =>{
  //                     if (product.category === item.value){
  //                         objBuscar.push(product)
  //                     }
  //                 })
  //             }
  //             state.productos.Funkos.map((product) =>{
  //                 if (product.category === item.value){
  //                     objBuscar.push(product)
  //                 }
  //             })

  //         })



  //         // setState({...state,filtroUno:e,productosBusqueda: objBuscar});
  //     }

  // const handleChangeMarcasSelect = (e) =>{

  //     if(titulo !== 'Funkos'){
  //         const objBuscar = [];

  //         e.map((item)=>{

  //              state.productos.Products.map((product) =>{
  //                 if (product.category === item.value){
  //                     objBuscar.push(product)
  //                 }
  //             })

  //         })



  //         // setState({...state,filtroDos:e,productosBusqueda: objBuscar});
  //     }

  // }





  return (

    <>

      {/* <Box sx={{ flexGrow: 1, marginTop:'6%', marginLeft:'4%',  marginRight:'4%'}}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
                <Grid item xs={2} sm={2} md={3} >
                <Select
                    value={state.filtroUno ? state.filtroUno : '' }
                    name="Filtro Uno"
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select-productos"
                    isSearchable={true}
                    placeholder = "Categorias"
                    closeMenuOnSelect={true}
                    options={OptionsCategorias}
                    onChange={options => handleChangeCategoriasSelect(options)}
                />
                </Grid>
                <Grid item xs={2} sm={2} md={2}>

                {titulo !== 'Funkos' ? <Select
                    value={state.filtroDos ? state.filtroDos : '' }
                    name="Filtro Dos"
                    isMulti
                    className="basic-multi-select"
                    classNamePrefix="select-productos"
                    isSearchable={true}
                    placeholder = "Marcas"
                    closeMenuOnSelect={true}
                    options={OptionsMarcas}
                    onChange={options => handleChangeMarcasSelect(options)}
                />
                : null}
                
                </Grid>
                <Grid item xs={4} sm={4} md={4} >
                <TextField
                label="Buscar por nombre"
                id="buscar-nombre"
                //sx={{height: '38px' }}
                fullWidth
                onChange={e => handleChangeBuscadorNombre(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>,
                }}
                />
                </Grid>
                
                
                </Grid>
        </Box> */}

      <Box sx={{ flexGrow: 1, marginTop: '10%', marginLeft: '4%', marginRight: '4%', marginBottom: '5%' }}>
        {true ?
          // <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
          //     { state.productosBusqueda.length > 0 ? 

          //     state.productosBusqueda.map((item, index) => (
          //         <Grid item xs={2} sm={2} md={2} key={index}>
          //          <Item>
          //          <CardActionArea onClick={()=>{goToProduct(item.name)}}>
          //             <img src={item.imageUrl[0]} style={{width:'226px', height: 'auto'}}/>
          //          </CardActionArea>
          //             <p>{item.name}</p>
          //             {/* <h6>{item.variation[0]}</h6> */}
          //             <p className='cardproductosstyle'>${item.price}</p>
          //             {item.inStock < 1 ?
          //             <ColorButtonNoStock  variant="contained">No Disponible</ColorButtonNoStock>
          //              :
          //              <ColorButton onClick={()=>{handleAddToCar({
          //                 "imagen": item.imageUrl[0],
          //                 "name": item.name,
          //                 "price": item.price,
          //                 // "variation": item.variation[0],
          //                 "id": item.id,
          //                 "cantidad": 1,
          //                 "inStock": item.inStock
          //             })}} variant="contained">AÑADIR AL CARRITO</ColorButton>}

          //         </Item>
          //         </Grid>
          //     ))
          //     : data.Funkos.map((item, index) => ( 
          //         <Grid item xs={2} sm={2} md={2} key={index}>
          //          <Item>
          //          <CardActionArea onClick={()=>{goToProduct(item.name)}}>
          //             <img src={item.imageUrl[0]} style={{width:'226px', height: 'auto'}}/>
          //          </CardActionArea>
          //             <p>{item.name}</p>
          //             {/* <h6>{item.variation[0]}</h6> */}
          //             <p className='cardproductosstyle'>${item.price}</p>
          //             {item.inStock < 1 ?
          //             <ColorButtonNoStock  variant="contained">No Disponible</ColorButtonNoStock>
          //              :
          //              //Enviando objeto que se necesita para recibir funko
          //              <ColorButton onClick={()=>{handleAddToCar({
          //                 "imagen": item.imageUrl[0],
          //                 "name": item.name,
          //                 "price": item.price,
          //                 // "variation": item.variation[0],
          //                 "id": item.id,
          //                 "cantidad": 1,
          //                 "inStock": item.inStock
          //             })}} variant="contained">AÑADIR AL CARRITO</ColorButton>}

          //         </Item>
          //         </Grid>
          //     ))}
          // </Grid>
          null
          :
          null
          // <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 10 }}>
          //     { state.productosBusqueda.length > 0 ? 

          //     state.productosBusqueda.map((item, index) => (
          //         <Grid item xs={2} sm={2} md={2} key={index}>
          //          <Item>
          //          <CardActionArea onClick={()=>{goToProduct(item.name)}}>
          //             <img src={item.imageUrl[0]} style={{width:'200px', height: '300px'}}/>
          //          </CardActionArea>
          //             <p>{item.name}</p>
          //             {/* <h6>{item.variation[0]}</h6> */}
          //             <p className='cardproductosstyle'>${item.price}</p>
          //             {item.inStock < 1 ?
          //             <ColorButtonNoStock  variant="contained">No Disponible</ColorButtonNoStock>
          //              :
          //              <ColorButton onClick={()=>{handleAddToCar({
          //                 "imagen": item.imageUrl[0],
          //                 "name": item.name,
          //                 "price": item.price,
          //                 // "variation": item.variation[0],
          //                 "id": item.id,
          //                 "cantidad": 1,
          //                 "inStock": item.inStock
          //             })}} variant="contained">AÑADIR AL CARRITO</ColorButton>}

          //         </Item>
          //         </Grid>
          //     ))
          //     :  state.productosBusqueda.length === 0 && state.filtroDos.length > 0 ? <p className='BoxProductos'>:C no Tenemos por el momento productos </p> : data.Products.map((item, index) => (
          //         <Grid item xs={2} sm={2} md={2} key={index}>
          //          <Item>
          //          <CardActionArea onClick={()=>{goToProduct(item.name)}}>
          //             <img src={item.imageUrl[0]} style={{width:'200px', height: '300px'}}/>
          //          </CardActionArea>
          //             <p>{item.name}</p>
          //             {/* <h6>{item.variation[0]}</h6> */}
          //             <p className='cardproductosstyle'>${item.price}</p>
          //             {item.inStock < 1 ?
          //             <ColorButtonNoStock  variant="contained">No Disponible</ColorButtonNoStock>
          //              :
          //              <ColorButton onClick={()=>{handleAddToCar({
          //                 "imagen": item.imageUrl[0],
          //                 "name": item.name,
          //                 "price": item.price,
          //                 // "variation": item.variation[0],
          //                 "id": item.id,
          //                 "cantidad": 1,
          //                 "inStock": item.inStock
          //             })}} variant="contained">AÑADIR AL CARRITO</ColorButton>}

          //         </Item>
          //         </Grid>
          //     ))}
          // </Grid>

        }
      </Box>
    </>
  )
}