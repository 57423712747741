import { ADD_FUNKOS,
  ADD_TO_CAR,
  DELETE_PRODUCT_FROM_CAR,
  DECREMENT_PRODUCT_FROM_CAR,
  PAGANDO,
  USER_DATA_SHIPPING,
  CUPON } from "../types";

export default (state, action) => {
  const { payload, type } = action;
  let calc = 0;

  switch (type) {

    case ADD_FUNKOS:
      return {
        ...state,
        funkos: payload,
      };

    case ADD_TO_CAR:

          let data = [];
          let buscar = false;

          if(state.carrito.length < 1){
            data = state.carrito;
            data.push(payload);
          }
          else if(state.carrito.length >= 1){

          buscar = state.carrito.find((item) =>{return item.id === payload.id});
          console.log(buscar)
          if(buscar !== undefined){

            data = state.carrito.map((item) =>{

              if(item.id === buscar.id){
                console.log('entro con ', item.cantidad )
                item.cantidad = item.cantidad +1
              }

              return item
            })

          }else{
            data = state.carrito;
            data.push(payload);
          }

        }

        localStorage.setItem('carrito',JSON.stringify(data))

        data.map((item) => {
          console.log("Primero",calc);
          console.log(item.cantidad, "item.cantidad")
          console.log(item.price, "item.price")
          calc = (item.cantidad * item.price) + calc;
          console.log("dESP",calc);
              
        })
        console.log(calc, "calc desde el reducer")
        localStorage.setItem('total',JSON.stringify(calc))

        return{
          ...state,
          carrito: data,
          total: calc
        }        
    case PAGANDO:
      return {
        ...state,
        nextStepPayment: payload,
      };

    case USER_DATA_SHIPPING: 
      return{
        ...state,
        envio: payload,
      };
    case DELETE_PRODUCT_FROM_CAR: 
      let dataDeleteProduct = [];
      dataDeleteProduct = state.carrito;
      dataDeleteProduct = state.carrito.filter((item) => item.id !== payload.id)

      localStorage.setItem('carrito',JSON.stringify(dataDeleteProduct))

      dataDeleteProduct.map((item) => {
          console.log("Primero",calc);
          calc = (item.cantidad * item.price) + calc;
          console.log("dESP",calc);
            
      })

      localStorage.setItem('total',JSON.stringify(calc))

      return{
        ...state,
        carrito: dataDeleteProduct,
        total: calc
      }       
    case DECREMENT_PRODUCT_FROM_CAR: 
        let dataDelete = [];
        let buscarToDelete = false;

        if(state.carrito.length < 1){
          dataDelete = state.carrito;
          dataDelete.push(payload);
        }
        else if(state.carrito.length >= 1){

        buscarToDelete = state.carrito.find((item) =>{return item.id === payload.id});
        console.log(buscarToDelete)
        if(buscarToDelete !== undefined && payload.cantidad > 1){

          dataDelete = state.carrito.map((item) =>{

            if(item.id === buscarToDelete.id){
              console.log('entro con ', item.cantidad )
              item.cantidad = item.cantidad -1
            }

            return item
          })

        }else{
          dataDelete = state.carrito.filter((item) => item.id !== payload.id)
        }

      }

      localStorage.setItem('carrito',JSON.stringify(dataDelete))

      dataDelete.map((item) => {
          console.log("Primero",calc);
          calc = (item.cantidad * item.price) + calc;
          console.log("dESP",calc);
            
      })

      localStorage.setItem('total',JSON.stringify(calc))

      return{
        ...state,
        carrito: dataDelete,
        total: calc
      } 
      
      case CUPON :
        return{
          ...state,
          cupon: payload,
        }  
    default:
      return state;
  }
};
