import React, {useContext, useState} from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import ToysContext from '../../context/ToysContext';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2'


const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor:'#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
  }));

const ContactForm = ()=>{

    const {setShipping, NextPayment,nextStepPayment, envio} = useContext(ToysContext);

    const [state, setState ] = useState({
        nombre: envio.nombre ? envio.nombre : '' ,
        email: envio.email ? envio.email : '',
        apellidos: envio.apellidos ? envio.apellidos : '',
        telefono: envio.telefono ? envio.telefono : '',
        colonia: envio.colonia ? envio.colonia : '',
        calle: envio.calle ? envio.calle : '',
        zipcode: envio.zipcode ? envio.zipcode : '',
        pais: envio.pais ? envio.pais : '',
        estado: envio.estado ? envio.estado : '',
        ciudad: envio.ciudad ? envio.ciudad : '',
    });
    const [Error, setError] = useState({
        nombreError:false,
        emailError:false,
        apellidosError:false,
        coloniaError:false,
        calleError:false,
        zipcodeError:false,
        ciudadError:false,
    });

    const Options = [
        { value: 'Mexico', label: '\uD83C\uDDF2\uD83C\uDDFD México' },
    ];

    const OptionsStates = [
        { value : "Aguascalientes" ,label: 'Aguascalientes'},
        { value : "Baja California" , label: 'Baja California'},
        { value : "Baja California Sur" , label: 'Baja California Sur'},
        { value : "Campeche" , label: 'Campeche'},
        { value : "Chiapas" , label: 'Chiapas'},
        { value : "Chihuahua" , label: 'Chihuahua'},
        { value : "Ciudad de México" , label: 'Ciudad de México'},
        { value : "Coahuila" , label: 'Coahuila'},
        { value : "Colima" , label: 'Colima'},
        { value : "Durango" , label: 'Durango'},
        { value : "Estado de México" , label: 'Estado de México'},
        { value : "Guanajuato " , label: 'Guanajuato'},
        { value : "Guerrero " , label: 'Guerrero'},
        { value : "Hidalgo " , label: 'Hidalgo'},
        { value : "Jalisco " , label: 'Jalisco'},
        { value : "Michoacán " , label: 'Michoacán'},
        { value : "Morelos " , label: 'Morelos'},
        { value : "Nayarit " , label: 'Nayarit'},
        { value : "Nuevo León " , label: 'Nuevo León'},
        { value : "Oaxaca " , label: 'Oaxaca'},
        { value : "Puebla " , label: 'Puebla'},
        { value : "Querétaro " , label: 'Querétaro'},
        { value : "Quintana Roo " , label: 'Quintana Roo'},
        { value : "Sinaloa " , label: 'Sinaloa'},
        { value : "San Luis Potosí " , label: 'San Luis Potosí'},
        { value : "Sonora " , label: 'Sonora'},
        { value : "Tabasco " , label: 'Tabasco'},
        { value : "Tamaulipas " , label: 'Tamaulipas'},
        { value : "Tlaxcala " , label: 'Tlaxcala'},
        { value : "Veracruz " , label: 'Veracruz'},
        { value : "Yucatán " , label: 'Yucatán'},
        { value : "Zacatecas " , label: 'Zacatecas'},
    ];


const onChange = (e, phone) =>{
    // console.log(phone);
    setState({...state,telefono:phone});
}

const onBlur = (e) =>{
    // console.log(e);
}

const handleEmail = (e) =>{
    setState({...state,email:e.target.value});
}
const handleName = (e) =>{
    setState({...state,nombre:e.target.value});
}
const handleLastName = (e) =>{
    setState({...state,apellidos:e.target.value});
}

const handleColonia = (e) =>{
    setState({...state,colonia:e.target.value});
}
const handleCalle = (e) =>{
    setState({...state,calle:e.target.value});
}
const handleCiudad = (e) =>{
    setState({...state,ciudad:e.target.value});
}

const handleEstado = (e) =>{
    setState({...state,estado:e});
}

const handlePais = (e) =>{
    setState({...state,pais:e});
}
const handleZipcode = (e) =>{
    setState({...state,zipcode:e.target.value});
}
const validate = () =>{

    const nombre = state.nombre === ''; 
    const email = state.email === '';
    const apellido = state.apellidos === '';
    const colonia = state.colonia === '';
    const calle = state.calle === '';
    const zipcode = state.zipcode === '';
    const ciudad = state.ciudad === '';
    const estado = state.estado === '';


    setError({
        nombreError: nombre,
        emailError:email,
        apellidosError:apellido,
        coloniaError:colonia,
        calleError:calle,
        zipcodeError:zipcode,
        ciudadError:ciudad,
    })

    return !nombre && !email && !apellido && !colonia && !calle && !zipcode && !ciudad && !estado
}

const NextStep = () =>{

    if(validate()){
        setShipping(
            {
                nombre:state.nombre,
                email:state.email,
                apellidos:state.apellidos,
                telefono:state.telefono,
                colonia:state.colonia,
                calle:state.calle,
                zipcode:state.zipcode,
                pais:state.pais,
                estado:state.estado,
                ciudad:state.ciudad,
            }
        );
        NextPayment(true);
    }
    else{

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por Favor llena todos los campos!',
          })
    }
    
}

return (

    <>
        <Box sx={{ width: '100%'}}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} >
        <h3>INFORMACION DE CONTACTO</h3>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} >
        <TextField
          required
          error={Error.emailError}
          fullWidth
          value={state.email}
          id="outlined-Email"
          label="Email"
          size="small"
          onChange={handleEmail}
        />
        </Grid>
        <Grid item xs={12}>
        <IntlTelInput
            preferredCountries={['mx']}
            onPhoneNumberChange={onChange}
            // onPhoneNumberBlur={onBlur}
        />
        </Grid>
        <Grid item xs={12} >
        <h3>DIRECCIÓN DE ENVÍO</h3>
        </Grid>
        <Grid item xs={12} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <TextField
                    required 
                    value={state.nombre}
                    error={Error.nombreError}
                    fullWidth
                    id="outlined-required"
                    label="Nombre"
                    size="small"
                    onChange={handleName}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        value={state.apellidos}
                        required
                        error={Error.apellidosError}
                        size="small"
                        onChange={handleLastName}
                        id="outlined-required"
                        label="Apellidos"
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <TextField
                    required
                    fullWidth
                    error={Error.coloniaError}
                    id="outlined-required"
                    label="Colonia"
                    value={state.colonia}
                    onChange={handleColonia}
                    size="small"
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                    <TextField
                    required
                    fullWidth
                    value={state.calle}
                    error={Error.calleError}
                    onChange={handleCalle}
                    id="outlined-required"
                    label="Calle y Numero"
                    size="small"
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <TextField
                    required
                    fullWidth
                    value={state.ciudad}
                    error={Error.ciudadError}
                    onChange={handleCiudad}
                    id="outlined-required"
                    label="Ciudad"
                    size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                <Select
                    className="basic-single"
                    classNamePrefix="select-pais"
                    isSearchable={true}
                    name="Estado"
                    value={state.estado}
                    placeholder = "Estado"
                    options={OptionsStates}
                    onChange={handleEstado}
                    />
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} >
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                    <TextField
                    required
                    fullWidth
                    value={state.zipcode}
                    onChange={handleZipcode}
                    error={Error.zipcodeError}
                    id="outlined-required"
                    label="Codigo Postal"
                    size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Select
                    className="basic-single"
                    classNamePrefix="select-pais"
                    defaultValue={Options[0]}
                    isSearchable={true}
                    name="Pais"
                    value={state.pais}
                    placeholder = "Pais"
                    options={Options}
                    onChange={handlePais}
                    />
                </Grid>
            </Grid>
        </Grid>
        {!nextStepPayment ? 
          <Grid item xs={12} >
            <ColorButton onClick={NextStep} variant="contained">CONTINUAR CON EL PAGO</ColorButton>
          </Grid>
          : <></>}
      </Grid>
    </Box>
    </>

)

}

export default ContactForm;