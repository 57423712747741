import React,  { useState }  from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import DataTable from '../DataTableComponents';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Swal from 'sweetalert2' 
import Modal from '@mui/material/Modal';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from 'react-select';

const CUPONES = gql`
    query Query {
  Discounts {
    created
    id
    status
    type
    typeDiscount
    discount
    acumulative
    code
    user
    validTo
  }
  Clients {
    lastName
    name
  }

}
`;

const CRUD = gql`
mutation Mutation($discount: DiscountInput!, $opcion: String!, $discountDelete: String) {
  DiscountCrud(discount: $discount, opcion: $opcion, discountDelete: $discountDelete) {
    acumulative
    code
    created
    id
    discount
    status
    type
    typeDiscount
    user
    validTo
  }
}

`;


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: '#fff',
    boxShadow: 24,
    borderRadius:'9px',
    p: 4,
    display: "flex",
    flexDirection: "column",
    height: 300,
    overflow: "hidden",
    overflowY: "scroll",
  };



const Discounts = ()=> {

  const {loading,error,data,} = useQuery(CUPONES);
  const [discountCrud] = useMutation(CRUD);
    

  const [state, setState] = useState({
    cupones:[],
    buscar:true,
    openModal:false,
    NuevoCupon:{
        "acumulative":false,
        "code": "",
        "discount": "",
        "status": "ACTIVO",
        "type": "PORCENTAJE",
        "typeDiscount": 0,
        "user": "",
        "validTo": "",
      },
    edit:false,
    fechaSelect:'',
    tipoDescuento:null,
    statusSelect:null,
    UsuarioSelect:null,
    usuariosList:[],
  });

  const columns = [
    {
        name: "code",
        label: "Code",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let code = state.cupones[dataIndex].code;
                return (<div style={{ height: '60px' }}>{code}</div>)
            }
        }
    },
    {
        name: "type",
        label: "type",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let type = state.cupones[dataIndex].type;
                return (<div style={{ height: '60px' }}>{type}</div>)
            }
        }
    },
    {
        name: "typeDiscount",
        label: "Descuento de",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let discount = state.cupones[dataIndex].discount;
                return (<div style={{ height: '60px' }}>{state.cupones[dataIndex].type === 'PORCENTAJE' ? `${discount} %` : `$ ${discount}` }</div>)
            }
        }
    },
    {
        name: "acumulative",
        label: "Acomulable",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let acumulative = state.cupones[dataIndex].acumulative;
                return (<div style={{ height: '60px' }}>{acumulative ? 'SI' : 'NO'}</div>)
            }
        }
    },
    {
        name: "validTo",
        label: "Fecha de vencimiento",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let validTo =new Date(parseInt(state.cupones[dataIndex].validTo));
                return (<div style={{ height: '60px' }}>{`${validTo.getDate()}/${validTo.getMonth() + 1}/${validTo.getFullYear()}`}</div>)
            }
        }
    },
    {
        name: "status",
        label: "Estatus",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                let estatus = state.cupones[dataIndex].status;
                return (<div style={{ height: '60px' }}>{estatus}</div>)
            }
        }
    },
    {
        name: "created",
        label: "Creado",
        options: {
            filter: true,
            sort: false,
            customBodyRenderLite: (dataIndex, i) => {
                const created = new Date(parseInt(state.cupones[dataIndex].created));
                return (<div style={{ height: '60px' }}>{`${created.getDate()}/${created.getMonth() + 1}/${created.getFullYear()}`}</div>)
            }
        }
    },
    {
        name: "acciones",
        label: "Acciones",
        align: "center",
        options: {
            filter: false,
            sort: false,
            download: false,
            customBodyRenderLite: (dataIndex, i) => (
                <>

                    <div style={{ height: '60px' }} className="d-flex">
                        <IconButton aria-label="edit" onClick={() => {
                            ModalEditar(state.cupones[dataIndex])
                        }}>
                            <EditIcon color='primary' />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => {
                            MensajeEliminar(state.cupones[dataIndex],state.cupones[dataIndex].id)
                        }}>
                            <DeleteForeverIcon color='secondary' />
                        </IconButton>
                    </div>
                </>
            )
        }
    },
];

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor: '#000000',
    '&:hover': {
        backgroundColor: '#000000',
    },
}));

    
const ModalEditar = (data) =>{
    setState({ ...state, openModal: true, 
        NuevoCupon:data,
        edit:true});
  }

const MensajeEliminar = (data, id)=>{


    Swal.fire({
        title: 'Estas seguro de eliminarlo?',
        text: "Si eliminas este Cupon lo borraras de la base de datos :c y no se podra seguir utilizando",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminalo!',
        cancelButtonText: 'No, Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            Eliminar(data,id)
        }
    })

}

const Eliminar = async (cupon, id) => {
   
    const { data, loading, error } = await discountCrud({
        variables: {
            discount:{
                "acumulative": cupon.acumulative,
                "code": cupon.code,
                "discount": cupon.discount,
                "status": cupon.status,
                "type": cupon.type,
                "typeDiscount": cupon.typeDiscount,
                "user": cupon.user,
                "validTo": cupon.validTo
              },
            discountDelete: id,
            opcion: "Delete"
        }
    });

    if(loading){return <>Eliminando...</>}

    if (error) {
        console.log('Algo salio mal -> ', error);
    }

    if (data.DiscountCrud) {
        const array = state.cupones.filter((item) => item.id !== id)
        setState({ ...state, cupones: array });
        Swal.fire(
            'Eliminado!',
            'el Cupon fue eliminado de tu base de datos :c.',
            'success'
        );
    }

}
 


  if(loading){return <>Cargando...</>}
  if(error){return <>Ups No hay Sistema...</>}

  if(state.buscar && data){

    setState({
        ...state,
        cupones:data.Discounts,
        usuariosList: data.Clients,
        buscar:false
    });

  }

  const options = {
    selectableRows: false,
    tableBodyHeight: 'auto',
    textLabels: {
        body: {
          noMatch: "Lo sentimos, no hay coincidencias en la busqueda",
          toolTip: "Clasificar",
          columnHeaderTooltip: column => `Clasificar por ${column.label}`
        },
        pagination: {
          next: "Siguiente",
          previous: "Anterior",
          rowsPerPage: "Filas por página:",
          displayRows: "de",
        },
        toolbar: {
          search: "Buscar",
          downloadCsv: "Descargar CSV",
          print: "Imprimir",
          viewColumns: "Ver columnas",
          filterTable: "Filtrar tabla",
        },
        filter: {
          all: "Todo",
          title: "Filtros",
          reset: "Restaurar",
        },
        viewColumns: {
          title: "Ver columnas",
          titleAria: "Mostrar/Ocultar columnas en la tabla",
        },
        selectedRows: {
          text: "columna(s) selecciona(as)",
          delete: "Eliminar",
          deleteAria: "Eliminar filas seleccionadas",
        },
      },
      responsive: 'standard',
      downloadOptions: {
        filename: 'tablaUsuarios.csv',
        filterOptions: {
        useDisplayedColumnsOnly: false,
        useDisplayedRowsOnly: false,
        }
      },

   };

  const editar = async() =>{
    
    if(validar()){

        state.NuevoCupon.discount = parseFloat(state.NuevoCupon.discount);

        const { data, loading, error } = await discountCrud({
            variables: {
                discount:{
                    "acumulative": state.NuevoCupon.acumulative,
                    "code": state.NuevoCupon.code,
                    "discount": state.NuevoCupon.discount,
                    "status": state.NuevoCupon.status,
                    "type": state.NuevoCupon.type,
                    "typeDiscount": state.NuevoCupon.typeDiscount,
                    "user": state.NuevoCupon.user,
                    "validTo": state.NuevoCupon.validTo
                  },
                opcion: "Update"
            }
        });
        if(loading){return <>Actualizando...</>}

        if (error) {
            console.log('Algo salio mal -> ', error);
        }

        if (data.DiscountCrud) {

            let cuponesNuevos = state.cupones.filter((item)=>{return item.id !== state.NuevoCupon.id});
            cuponesNuevos.unshift(data.DiscountCrud);


            setState({ ...state, openModal: false, 
                NuevoCupon:{
                    "acumulative":false,
                    "code": "",
                    "discount": "",
                    "status": "ACTIVO",
                    "type": "PORCENTAJE",
                    "typeDiscount": 0,
                    "user": "",
                    "validTo": "",
                  }, edit:false,
                  tipoDescuento:null,
                  statusSelect:null,
                  UsuarioSelect:null,
                  fechaSelect:'',
                  cupones:cuponesNuevos});
           
            Swal.fire(
                'Agregado!',
                'el Cupon fue Agregado con exito',
                'success'
            );
        }
    }
  }


  const validar = ()=>{

    const code = state.NuevoCupon.code !== '';
    const discount = state.NuevoCupon.discount !== '';
    const validTo = state.NuevoCupon.validTo !== '';
    
    if(!parseFloat(state.NuevoCupon.discount)){
        Swal.fire(
            'Ups!',
            'El formato de descuento no es valido ',
            'warning'
        );

        return false;
    }

    if(code && discount && validTo){
        return true;
    }else{

        Swal.fire(
            'Ups!',
            'Faltan algunos campos por llenar',
            'warning'
        );

        return false;

    }

    
  }

  const AddaDB = async() =>{

    if(validar()){

        state.NuevoCupon.discount = parseFloat(state.NuevoCupon.discount);
        state.NuevoCupon.user = state.UsuarioSelect.id

        const { data, loading, error } = await discountCrud({
            variables: {
                discount:state.NuevoCupon,
                opcion: "Add"
            }
        });
        if(loading){return <>Eliminando...</>}

        if (error) {
            console.log('Algo salio mal -> ', error);
        }

        if (data.DiscountCrud) {

            let cuponesNuevos = state.cupones.map((item)=>{return item});
            cuponesNuevos.unshift(data.DiscountCrud);


            setState({ ...state, openModal: false, 
                NuevoCupon:{
                    "acumulative":false,
                    "code": "",
                    "discount": "",
                    "status": "ACTIVO",
                    "type": "PORCENTAJE",
                    "typeDiscount": 0,
                    "user": "",
                    "validTo": "",
                  }, edit:false,
                  tipoDescuento:null,
                  statusSelect:null,
                  UsuarioSelect:null,
                  fechaSelect:'',
                  cupones:cuponesNuevos});
           
            Swal.fire(
                'Agregado!',
                'el Cupon fue Agregado con exito',
                'success'
            );
        }
    }
    

    
  }

  const handleChangeDescuentoSelect = (e)=>{
    setState({...state,NuevoCupon:{...state.NuevoCupon, type:e.value}, tipoDescuento:e })
  }

  const handleChangeStatusSelect = (e) =>{
    setState({...state,NuevoCupon:{...state.NuevoCupon, status:e.value}, statusSelect:e })
  }

  const handleCloseModal = () =>{
    setState({ ...state, openModal: false, 
        NuevoCupon:{
            "acumulative":false,
            "code": "",
            "discount": "",
            "status": "ACTIVO",
            "type": "PORCENTAJE",
            "typeDiscount": 0,
            "user": "",
            "validTo": "",
          }, edit:false,
          tipoDescuento:null,
          statusSelect:null,
          UsuarioSelect:null,
          fechaSelect:'',});
  }

  const handleValido = (e) =>{
    console.log(e);
    setState({...state,NuevoCupon:{...state.NuevoCupon, validTo:e.$d}, fechaSelect:e })
  }

  const OptionsTipoDescuento =[
    { value: 'PORCENTAJE', label: 'Por Porcentaje' },
    { value: 'NUMERICO', label: 'Descuento Numerico' },
  ];

  const OptionsStatus =[
    { value: 'ACTIVO', label: 'ACTIVO' },
    { value: 'DESACTIVADO', label: 'DESACTIVADO' },
  ];

  const OptionsUsuarios =[];

  const handleChangeUsuarioSelect =(e)=>{
        setState({...state, UsuarioSelect: e})
  }
  
    return(<>


            <div >
                <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="right" item xs={12} sm={6} md={6}>
                        <ColorButton startIcon={<LocalOfferIcon />} onClick={() => { setState({ ...state, openModal: true }) }}>
                            Nuevo Cupon
                        </ColorButton>
                    </Grid>
                </Grid>
            </div>

            <DataTable
                data={state.cupones}
                title={`Tabla de cupones`}
                columns={columns}
                options={options}
            />


<Modal
            open={state.openModal}
            onClose={handleCloseModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
           <Box sx={style}>
           <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} sx={{marginBottom:'3%'}} >
                    <h5>{state.NuevoCupon.code !== '' ? state.NuevoCupon.code : 'Nuevo Cupon'}</h5>
                </Grid>
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Codigo"
                    variant="outlined"
                    value={state.NuevoCupon.code}
                    onChange={(e) =>{setState({...state,NuevoCupon:{...state.NuevoCupon,code: e.target.value}});}}
                    />
                </Grid>
               
                
                
                
                <Grid item xs={3}>
                <TextField 
                    id="outlined-basic" 
                    label="Descuento"
                    variant="outlined"
                    value={state.NuevoCupon.discount}
                    onChange={(e) =>{setState({...state,NuevoCupon:{...state.NuevoCupon,discount: e.target.value}})}}
                    />

                </Grid>
                <Grid item xs={3}>
                <Select
                    value={state.tipoDescuento ? state.tipoDescuento : '' }
                    name="Tipo Descuento"
                    className="basic-multi-select"
                    classNamePrefix="select-productos"
                    isSearchable={true}
                    placeholder = "Tipo Descuento"
                    closeMenuOnSelect={true}
                    options={OptionsTipoDescuento}
                    onChange={options => handleChangeDescuentoSelect(options)}
                />
                
                </Grid>
                <Grid item xs={3}>
                <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">acumulativo</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={state.NuevoCupon.acumulative}
                        onChange={(e) => {setState({...state,NuevoCupon:{...state.NuevoCupon,acumulative: e.target.value}});}}
                    >
                        <FormControlLabel value={true} control={<Radio />}   label="SI" />
                        <FormControlLabel value={false} control={<Radio />}  label="NO" />
                    </RadioGroup>
                </FormControl>
                </Grid>
                <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                    components={[
                    'MobileDatePicker',
                    'DesktopDatePicker',
                    ]}
                >
                    <DemoItem >
                    <DatePicker 
                    label="Valido Hasta"  
                    value={state.fechaSelect}
                    onChange={handleValido}/>
                    </DemoItem>
                    
                </DemoContainer>
                </LocalizationProvider> 
                </Grid>
                
                <Grid item xs={3}>
                <Select
                    value={state.statusSelect ?  state.statusSelect  : ''}
                    name="TipoStatus"
                    className="basic-multi-select"
                    classNamePrefix="select-productos"
                    isSearchable={true}
                    placeholder = "Status"
                    closeMenuOnSelect={true}
                    options={OptionsStatus}
                    onChange={options => handleChangeStatusSelect(options)}
                />
                </Grid>
                <Grid item xs={3}>
                <Select
                    value={state.UsuarioSelect ?  state.UsuarioSelect  : ''}
                    name="TipoStatus"
                    className="basic-multi-select"
                    classNamePrefix="select-productos"
                    isSearchable={true}
                    placeholder = "Usuario (Opcional)"
                    closeMenuOnSelect={true}
                    options={state.usuariosList}
                    getOptionLabel={(e)=>`${e.name} ${e.lastName}`}
                    onChange={options => handleChangeUsuarioSelect(options)}
                />
                </Grid>
                <Grid item xs={3}>
                
                </Grid>
                <Grid align={'right'} item xs={12}>
                    <ColorButton startIcon={<AddCircleOutlineIcon />} onClick={() =>{ state.edit ?  editar() : AddaDB()}}>
                        Guardar
                    </ColorButton>
                </Grid>
            </Grid>
            </Box>
        </Modal>
        
    
    
    </>);
}



export default Discounts;