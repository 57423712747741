import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";



const ProductCarousel = ( Data) =>{
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const {imagenes} = Data;

    return (
        <>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >

            {imagenes.map((item) => (
               <SwiperSlide>
                <img src={item} style={{width:'60%', height:'auto'}} />
                </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {imagenes.map((item) => (
               <SwiperSlide>
                <img src={item} style={{width:'65%', height:'auto'}}/>
                </SwiperSlide>
            ))}
          </Swiper>
        </>
      );

}





export default ProductCarousel;