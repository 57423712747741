import React, {useState, useEffect, useContext} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import ToysContext from '../context/ToysContext';
import ProductCarousel from '../components/Shop/CarouselProductoView';
import DescripcionProducto from '../components/Shop/DescripcionProductoComponent';
import CarouserB from '../components/Shop/CarouselComponentB';
import { CiDeliveryTruck } from 'react-icons/ci';
import Button from '@mui/material/Button';
import VisaSvg from '../assets/img/visa.svg';
// import MasterCardSvg from '../assets/img/master-card.svg';
import Swal from 'sweetalert2';

const FUNKOS = gql`
query FunkoByName($name: String) {
  FunkoByName(name: $name) {
    description
    category
    name
    nomenclature
    imageUrl
    inStock
    material
    price
    variation
  }
  ProductByName(name: $name) {
    category
    color
    created
    description
    discount
    flor
    imageUrl
    inStock
    name
    price
    status
    
  }
}
`;



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const ProductView = () =>{

    const {addcar} = useContext(ToysContext);

     let { id } = useParams();
     let navigation = useNavigate();

     const [state, SetState] = useState({
        productData :{},
        encontrado :true,
     });

     const {loading,error,data,} = useQuery(FUNKOS, {
        variables: {
            name:id
        },
    });

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            
        }

        return () => {
            isMounted = false
        }
    }, [])

    const handleAddToCar = (product) => {
        console.log("MI FUNKO", product)
        addcar(product)
        Swal.fire('Listo!', 'El producto se agregó correctamente al carrito!.', 'success')
    }

    const seleccion = (item) =>{
        console.log(item);
        if(state.encontrado){
            SetState({...state, productData : item, encontrado: false});
        }

    }
        

        if(loading) {return <>Cargando...</>}
        if(error){navigation('/')}
        if(data){
            const {FunkoByName, ProductByName} = data;
            if(FunkoByName === null){
                seleccion(ProductByName);
            }else{
                seleccion(FunkoByName);
            }
        }
 
    return(

    <>

    {!state.encontrado ? 
    <>

     <Box sx={{ width: '100%', marginTop:'80px', marginBottom:'120px' }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={7} sx={{ height: '610px'}}>
          <ProductCarousel
          imagenes ={state.productData.imageUrl}
          />
        </Grid>
        <Grid item xs={5}>
            <Item style={{backgroundColor:'#F8F8F8', marginRight:'10px'}}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid style={{color:'gray', textAlign: 'left', marginLeft: '10px'}} item xs={12}>
                        Nuevo
                    </Grid>
                    <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <h4><b>{state.productData.name}</b></h4>
                    </Grid>
                    <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <h3>${state.productData.price}</h3>
                    </Grid>
                    <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <p style={{fontSize: '15px', color: '#66bb6a'}}><CiDeliveryTruck style={{fontSize: '25px'}}/> Entrega a acordar con el vendedor.</p>
                    </Grid>
                    {/* <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <p style={{fontSize: '15px'}}> <b>Material:</b> {state.productData.material}</p>
                    </Grid> */}
                    <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <p style={{fontSize: '15px'}}> <b>Categoria:</b> {state.productData.category}</p>
                    </Grid>
                    <Grid item xs={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <p style={{fontSize: '15px'}}> <b>Existencias:</b> {state.productData.inStock}</p>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Button style={{width:'100%', height: '50px'}} variant="contained">Comprar ahora!</Button>
                    </Grid> */}
                    <Grid item xs={12}>
                        <Button onClick={()=>{handleAddToCar({
                                "imagen": state.productData.imageUrl[0],
                                "name": state.productData.name,
                                "nomenclature": state.productData.nomenclature,
                                "price": state.productData.price,
                                "variation": state.productData.color[0],
                                "id": state.productData.id,
                                "cantidad": 1,
                                "inStock": state.productData.inStock
                            })}} style={{width:'100%', height: '50px'}} variant="outlined">Agregar al carrito</Button>
                    </Grid>
                    <Grid item xs={12} sx={{color:'gray', textAlign: 'left', marginLeft: '10px', marginTop: '10px'}}>
                        <p style={{fontSize: '15px'}}><b>Medios de pago</b></p>
                        <hr></hr>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        <img src={VisaSvg} width="160px"/>
                    </Grid>
                    <Grid item xs={6} sx={{textAlign: 'center'}}>
                        {/* <img src={MasterCardSvg} width="160px"/> */}
                    </Grid>
                </Grid>
            </Item>
        </Grid>
        <Grid item xs={12}>
          <DescripcionProducto
          modelo={state.productData.category}
          descripcion={state.productData.description}
          precio = {state.productData.price}
          color =  {state.productData.color}
          />
        </Grid>
        <Grid item xs={12}>
        {/* <h3 className='bannerProductosRecomendados' > Productos Recomendados para Seguir con tu Colección de {state.productData.category ? state.productData.category : state.productData.name ? state.productData.name : ''} </h3> */}
          {/* <CarouserB/> */}
        </Grid>
      </Grid>
    </Box>
    
    </> : <></> }
    

    </>
    );
}


export default ProductView;