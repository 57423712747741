import React, {useContext} from 'react'
import Payment from '../components/Shop/PaymentComponent';
import ContactForm from '../components/Shop/ContactFormComponent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ToysContext from '../context/ToysContext';
import PaymentFinally from '../components/Shop/PaymentFinallyComponent';

const PaymentView = ()=>{

  const {nextStepPayment} = useContext(ToysContext);

return (

    <>
        <Box sx={{ width: '100%', marginTop:'80px', marginBottom:'120px' }}>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} sx={{ height: '610px'}}>
          {!nextStepPayment ? 
          <ContactForm/>
          : 
          <PaymentFinally/>
          }
        </Grid>
        <Grid item xs={6}>
           <Payment/>
        </Grid>
      </Grid>
    </Box>

    </>

)

}

export default PaymentView;