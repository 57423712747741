import React, { useEffect } from 'react'
import DefaultCarousel from '../components/Shop/CarouselComponentA';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CardProduct from '../components/Shop/CardProductsComponent';
import CardCategorias from '../components/Shop/CardCategoriasComponent';
import Informacion from '../components/Shop/InfoComponent';
import BannerPromocional from '../components/Shop/BannerComponent';
import SocialNetwork from '../components/Shop/SocialNetworkComponent';
// import pikachu from '../assets/img/pikachu.png';
import CarouserBestSales from '../components/Shop/CarouselBestSalesComponent';
// import Hot_Wheels_Logo from '../assets/img/Hot_Wheels_Logo.png';
// import Funko_Logo from '../assets/img/Funko_Logo.png';
// import bandai from '../assets/img/bandai.png';
// import bannertest from '../assets/img/bannertest.png';
import { useQuery, gql } from "@apollo/client";
import SectionWipes2 from '../components/Shop/ScrollmagicComponent';
import Fab from '@mui/material/Fab';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Link from '@mui/material/Link';
import newsletter from '../assets/img/newsletter.png'


import CardProductsHome from "../components/Shop/ModuloProductosHome";


const SLIDERPRINCIPAL = gql`
query Query {
  SliderActivo {
    url
  }
  AllSeasonProducts {
    producto {
      description
      name
      id
      inStock
      price
      # material
      # variation
      imageUrl
      # brand
      status
      created
      typeDiscount
      category
      discount
    }
    funko {
      id
      name
      nomenclature
      description
      inStock
      price
      material
      variation
      imageUrl
      category
      status
      created
      typeDiscount
      discount
    }
  }
  whatsapp {
    activo
    label
    mensaje
    url
  }
  SocialConfigActivos {
    activo
    label
    mensaje
    url
  }
}
`;




const HomeView = () => {

  const { loading, error, data, } = useQuery(SLIDERPRINCIPAL);


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (!(localStorage.getItem('carrito'))) {
        localStorage.setItem('carrito', JSON.stringify([]))
      }
    }
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div style={{ overflow: 'hidden' }}>
      {
        loading || error ? <>CARGANDO....</>
          :

          <DefaultCarousel
            imagenes={data.SliderActivo}
          />
      }

      <BannerPromocional
        imagen=''
        texto='Regalos especiales para alguien especial...✨'
        // ancho='300px'
        ancho='100px'
        color='#F58BBD'
        fontSize= "30px"
      // color='-webkit-linear-gradient(70deg, red, yellow, blue)'
      />

      <CardProductsHome />
      <br />
      <h3>Newsletter</h3>
      <br />
      <Box className='GridCategorias' sx={{ flexGrow: 1 }}>
        <div className='d-flex justify-content-center'>
          <Grid item xs={1} sm={4} md={4}>
            <div className='d-flex flex-column justify-content-center mx-5'>
              <div className=' w-100'>
                <img src={newsletter} className='w-100' />
              </div>
              <div className='mt-3'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi dicta provident voluptatem vel magni laborum odit? Beatae veniam corporis laborum magnam aperiam optio accusamus quos eligendi praesentium natus, voluptate omnis.
              </div>
            </div>
          </Grid>
          <Grid mx={'15px'} item xs={1} sm={4} md={4}>

            <div className='d-flex flex-column justify-content-center mx-5'>
              <div className=' w-100'>
                <img src={newsletter} className='w-100' />
              </div>
              <div className='mt-3'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi dicta provident voluptatem vel magni laborum odit? Beatae veniam corporis laborum magnam aperiam optio accusamus quos eligendi praesentium natus, voluptate omnis.
              </div>
            </div>
          </Grid>
          <Grid item xs={1} sm={4} md={4}>
            <div className='d-flex flex-column justify-content-center mx-5'>
              <div className=' w-100'>
                <img src={newsletter} className='w-100' />
              </div>
              <div className='mt-3'>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi dicta provident voluptatem vel magni laborum odit? Beatae veniam corporis laborum magnam aperiam optio accusamus quos eligendi praesentium natus, voluptate omnis.
              </div>
            </div>
          </Grid>

        </div>
      </Box>
      {/* <Box className='GridCategorias'  sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}  columns={{ xs: 1, sm: 8, md: 16 }}>
                <Grid item xs={1} sm={4} md={4}>
                <CardCategorias
                data={{imagen:Funko_Logo, titulo: 'Funkos'}} 
                />
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                <CardCategorias
                data={{imagen:Hot_Wheels_Logo, titulo: 'Hotwheels'}}
                />
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                <CardCategorias
                data={{imagen:bandai, titulo: 'Figuras'}}
                />
                </Grid>
                <Grid item xs={1} sm={4} md={4}>
                <CardCategorias
                data={{imagen:bannertest, titulo: 'Todos los productos'}}
                />
                </Grid>
            </Grid>
        </Box> */}

      {/* <br />
        <h3>Productos de Temporada</h3> */}
      <br />
      {/* {
        loading || error ? <>CARGANDO....</> 
        :
        <Box  sx={{ flexGrow: 1, marginLeft:'4%',  marginRight:'4%'}}>
            <Grid container spacing={2} columns={{ xs: 1, sm: 8, md: 16 }}>
                {data.AllSeasonProducts?.map((item) => (
                  
                    <Grid item xs={1} sm={5} md={5}>
                    <CardProduct
                        id={item.funko.id}
                        imagen={item.funko.imageUrl ? item.funko.imageUrl[0]: null  }
                        name={item.funko.name}
                        nomenclature={item.funko.nomenclature}
                        price={item.funko.price} 
                        variation={item.funko.variation[0]}
                        cantidad={1}
                        inStock={item.funko.inStock}
                    />
                    </Grid>
                ))}
            </Grid>
        </Box>
        } */}
      <br />
      <br />
      {/* <BannerPromocional
         imagen = {Hot_Wheels_Logo}
         ancho = '70px'
         imagenWidth='auto'
         color = '#3c9ddf'/> */}
      <br />
      {/* <h3>Slider Productos mas Vendidos</h3> */}
      <br />
      {/* <CarouserBestSales/> */}
      <br />
      {/* <SectionWipes2/> */}

      {/* <BannerPromocional
        imagen = ''
        texto = 'Promociones aqui (con texto o imagen)'
        ancho = '300px'
        color = '-webkit-linear-gradient(70deg, red, yellow, blue)'
        /> */}
      {
        loading || error ? <>CARGANDO....</>
          :
          <SocialNetwork data={data.SocialConfigActivos} />
      }
      <br />
      <h3>Informacion al usuario </h3>
      <Informacion />
      <br />
      <br />
      <br />
      <br />
      <br />

      {
        loading || error ? <>CARGANDO....</>
          :
          <>
            {data.whatsapp.activo ?
              <Fab color="success" aria-label="add" className='whatsappButton'>
                <Link color='#FFF' href={`https://wa.me/${data.whatsapp.url}/?texto=${data.whatsapp.mensaje}`} target='_blank'>
                  <WhatsAppIcon color='#FFF' />
                </Link>
              </Fab>
              : <></>}
          </>
      }
    </div>


  );
}




export default HomeView;