import { useState, useContext, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// import VisaSvg from '../assets/img/visa.svg';
// import MasterCardSvg from '../assets/img/master-card.svg';
// import miniProduct from '../assets/img/mini_product.jpg';
import { styled } from '@mui/material/styles';
import { CiDeliveryTruck } from 'react-icons/ci';
import ToysContext from '../context/ToysContext';
import Swal from 'sweetalert2'
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CardActionArea } from '@mui/material';
// import ButtonGroup from "@material-ui/core/ButtonGroup";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://mui.com/">
                Your Website
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();




const Carrito = () => {

    const navigate = useNavigate();

    const {addcar, carrito, decrementProductFromCar, deleteProductFromCar, total} = useContext(ToysContext);

    const toMexico = (number) => {
		const exp = /(\d)(?=(\d{3})+(?!\d))/g;
		const rep = '$1,';
		let num_clear=number.toString().replaceAll("$", "").replaceAll(" ","").replaceAll(",","");
		let arr = num_clear.split('.');
		arr[0] = arr[0].replace(exp,rep);
		if(arr[1]){
			if(arr[1] == '0'){
				arr[1] = "00";
			}
			return arr.join('.');
		}else{
			return arr[0]+".00";
		}
	}

    const goToProduct = (product)=>{
        navigate(`/producto/${product}`);
    }

   
    const handleIncrement = (data) => {
        addcar(data)
    };
    
    const handleDecrement = (data) => {
        decrementProductFromCar(data)
    };
    
    const continueBuying = () => {
        Swal.fire({
            title: 'Deseas continuar con la compra del carrito?',
            icon: 'warning',
            showDenyButton: true,
            confirmButtonText: 'Si!',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
                try{
                    setTimeout(() => {
                        navigate('/Pago', { replace: true });
                    }, 1000);
                }catch(e){
                    console.log('Error->', e);
                    Swal.fire(
                        'Ha ocurrido un error!',
                        e,
                        'error'
                    )
                }
              
            } else if (result.isDenied) {
                Swal.fire('La operación fue cancelada', '', 'info')
            }   
          })
    };


    const GroupedButtons = ({data}) => {
        
        
         
        const displayCounter = data.cantidad > 0;
        let isDisabled = false;
        if (data.cantidad >= data.inStock){
            isDisabled = true;
        }

        let isDisabledDecrement = false;
        if (data.cantidad === 1){
            isDisabledDecrement = true;
        }
    
        return (
        <Button size="small" aria-label="small outlined button group">
            <Button disabled={isDisabled ? true : false} variant="outlined" onClick={() => {handleIncrement(data)}}>+</Button>
            {displayCounter && <Button>{data.cantidad}</Button>}
            {displayCounter && <Button disabled={isDisabledDecrement ? true : false} variant="outlined" onClick={() => {handleDecrement(data)}}>-</Button>}
        </Button>
        );
        
    }

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
      }));

    return (
        <div style={{paddingTop:'100px', paddingBottom:'8%', marginBottom: '-1%',backgroundColor:'#E8E8E8'}}>
            <Item style={{marginLeft:'10px' ,marginRight:'10px'}}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12} sx={{color:'primary.main', textAlign: 'left', marginLeft: '10px', marginTop: '15px'}}>
                        <h5><b>Carrito</b></h5>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{color:'black', textAlign: 'left', marginLeft: '10px'}}>
                        <hr style={{color:'blue', height: '3px'}}/>
                    </Grid>
                </Grid>
                {carrito.length === 0 ? 
                    <h1>No hay productos en el carrito :'(</h1>
                :
                    <>
                    {carrito.map(function(item){
                        {console.log("My item",item)}
                        return(
                        <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={2}>
                                <img width={70} height={70} src={item.imagen} alt="" />
                            </Grid>
                            <Grid item md={6} sx={{textAlign: 'left'}}>
                                <b>{item.name}</b>
                            </Grid>
                            <Grid item md={2}>
                                <GroupedButtons data={item} />
                            </Grid> 
                            <Grid item md={2}>
                                <h5>$ {toMexico(item.price * item.cantidad)}</h5>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item md={2}>
                            </Grid>
                            <Grid item md={3} sx={{textAlign: 'left'}}>
                                <button style={{backgroundColor:'white', color: '#0d6efd', border: 'none', textDecoration: 'underline'}} onClick={() => { deleteProductFromCar(item) }}>Eliminar</button>
                            </Grid>
                            <Grid item md={3} sx={{textAlign: 'left'}}>
                            <CardActionArea onClick={()=>{goToProduct(item.name)}}>
                                <a href="#">Comprar ahora</a>
                            </CardActionArea>
                            </Grid>
                            <Grid item md={2}>
                                <p>{item.inStock} Disponibles</p>
                            </Grid>
                            <Grid item md={2}>
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={1}>
                            <Grid item md={2}></Grid>
                            <Grid item md={8}>
                                <hr />
                            </Grid>
                            <Grid item md={2}></Grid>
                        </Grid>
                        </>
                        )
                    })};
                

                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={8}>
                        </Grid>
                        <Grid item md={2}>
                            <b>Envío</b>
                        </Grid>
                        <Grid item md={2}>
                        <p style={{fontSize: '15px', color: '#66bb6a'}}><CiDeliveryTruck style={{fontSize: '25px'}}/> Calculado en el siguiente paso.</p>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1}>
                        <Grid item md={2}></Grid>
                        <Grid item md={8}>
                            <hr />
                        </Grid>
                        <Grid item md={2}></Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={8}>
                        </Grid>
                        <Grid item md={2}>
                            <h5>Total</h5>
                        </Grid>
                        <Grid item md={2}>
                        <h5>$ {toMexico(total)}</h5>
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1}>
                        <Grid item md={2}></Grid>
                        <Grid item md={8}>
                            <hr />
                        </Grid>
                        <Grid item md={2}></Grid>
                    </Grid>
                    <Grid style={{marginTop:'20px', marginBottom: '30px'}} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item md={8}>
                        </Grid>
                        <Grid item md={4}>
                        <Button style={{height:'40px'}} onClick={() => {continueBuying()}}  variant="contained">Continuar compra</Button>
                        </Grid>
                    </Grid>
                    </>

                }
                    
                
            </Item>
        </div>
    );
}


export default Carrito;