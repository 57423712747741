import React, { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LOGO from '../assets/img/logo.png';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ToysContext from '../context/ToysContext';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import ToysIcon from '@mui/icons-material/Toys';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import { useLocation } from "react-router-dom";
// import { styled } from '@mui/system';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const theme = createTheme({
  palette: {
    carrito: {
      main: '#386cc6',
      contrastText: '#fff',
    },
  },
});


const HeaderComponent = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const [anchorEl, setAnchorEl] = useState(null);
  const user = localStorage.getItem('Usuario') ? JSON.parse(localStorage.getItem('Usuario')) : null;
  const { carrito } = useContext(ToysContext);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [state, setState] = useState({
    openMenuMobil: false,
  });

  const handleClose = () => {
    setAnchorEl(null);
  };


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, openMenuMobil: open });
  };

  const cerrarSesion = () => {
    localStorage.clear();
    navigate(`/`)
    setAnchorEl(null);
  }

  const GoToAdmin = () => {
    navigate(`/administrador`)
    setAnchorEl(null);
  }

  const GoToLogin = () => {
    navigate(`/login`)
    setAnchorEl(null);
  }

  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#e34791'
    // color: 'white'
  };

  const StyledBox = styled(Box)({
    height:'100%',
    // background:'#e34791',
    // background:'#F58BBD',
    background:'white',
    // '&:hover': {
    //   backgroundColor: '#e0e0e0', 
    //   cursor: 'pointer', 
    // },
  });

  const list = () => (
    <StyledBox
      sx={{width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      {user ?
        <>
          <List>
            {['Mi Cuenta'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton onClick={() => {
                  navigate(`/Perfil`)
                }}>
                  <ListItemIcon>
                    <Avatar src={user ? user.FotoPerfil : ''} sx={{ bgcolor: 'transparent' }} alt="Sesion" ><AccountCircleIcon />  </Avatar>
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </> : <></>}
      <Box sx={{ background: '' }} mt={'20px'}>
        <Box sx={style}>MENÚ</Box>
        <List>
          {/* {['BLOSSOM CLUB', 'SUBSCRIPCIÓNES', 'PRODUCTOS', 'NEWSLETTER'].map((text, index) => ( */}
           {['Blossom Club', 'Suscripciones', 'Productos', 'Newsletter'].map((text, index) => (
            <>
              {/* <Divider /> */}
              <ListItem key={text} disablePadding>
                <ListItemButton sx={{color:'#F58BBD'}} onClick={() => {
                  // navigate(`/categorias/${text === 'FUNKOS' ? 'Funkos' : text === 'HOTWHEELS' ? 'Hotwheels' : text === 'FIGURAS' ? 'Figuras' : 'Pre-ventas'}`)
                  navigate(`${text === 'Blossom Club' ? 'Club-Blossom' : text === 'Suscripciones' ? 'Suscripciones' : text === 'Productos' ? 'Productos' : 'Club'}`)
                }}>
                  <ListItemIcon sx={{color:'#F58BBD'}}>
                    {index === 0 ? <AccessibilityNewIcon /> : index === 1 ? <AccessAlarmsIcon /> : index === 2 ? <ToysIcon /> : <EmojiPeopleIcon />}
                  </ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            </>
          ))}
        </List>
      </Box>

      <Divider/>
      {user ?
        <List>
          {['Cerrar Sesión'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => {
                cerrarSesion()
              }}>
                <ListItemIcon>
                  <MeetingRoomIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        :
        <List sx={{color:'#F58BBD'}}>
          {/* {['INICIAR SESIÓN'].map((text, index) => ( */}
          {['Iniciar Sesión'].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton onClick={() => {
                navigate(`/login`)
              }}>
                <ListItemIcon sx={{color:'#F58BBD'}}>
                  <LoginIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      }
    </StyledBox>
  );

  const body = document.body;
  const MenuNav = document.querySelector(".header");

  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
      // lottiePlayer.play();
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
      // lottiePlayer.stop();
    }
    lastScroll = currentScroll;
  });

  return (
    <ThemeProvider theme={theme}>
      <div className="header">
        <Grid
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: 1 }} container spacing={0}>
          <Grid className='HeaderMobile' item xs={1}>
            <IconButton style={{ color: '#e34791', marginTop: '12%' }} aria-label="Menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid className='HeaderMobile' item xs={1}></Grid>
          <Grid className='HeaderMobile' item xs={1}></Grid>

          <Grid className='HeaderDesktop' align="center" item xs={1}>
            <IconButton style={{ color: '#e34791', marginTop: '12%' }} aria-label="Menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid className='HeaderDesktop' item xs={1} align="center" >
            {/* <Button sx={{ color: 'white', marginTop:'12%', fontWeight:'bolder', fontSize:'0.975' }} onClick={() => { navigate('/categorias/pre-ventas') }}>Subscripciónes</Button> */}
          </Grid>
          <Grid className='HeaderDesktop' item xs={1} align="center" >
            {/* <Button sx={{ color: 'white',  marginTop:'12%' , fontWeight:'bolder', fontSize:'0.975'}} onClick={() => { navigate('/categorias/HotWheels') }}>HotWheels</Button> */}
          </Grid>
          <Grid align="center" item xs={3}>
            {
              location.pathname === "/administrador" ? null :
                <Button sx={{ color: '#e34791' }} onClick={() => { navigate('/') }}><img style={{ width: '250px', height: '70px', top: '1%', position: 'fixed' }} src={LOGO} /></Button>
            }
          </Grid>
          <Grid className='HeaderDesktop' item xs={1} align="center" >
            {/* <Button sx={{ color: 'white',  marginTop:'12%', fontWeight:'bolder', fontSize:'0.975' }} onClick={() => { navigate('/categorias/Figuras') }}>Figuras</Button> */}
          </Grid>
          <Grid className='HeaderDesktop' item xs={1} align="center" >
            {/* <Button sx={{ color: 'white',  marginTop:'12%' , fontWeight:'bolder', fontSize:'0.975'}} onClick={() => { navigate('/categorias/Todos%20los%20productos') }}>Productos</Button> */}
          </Grid>
          <Grid className='HeaderMobile' item xs={1}></Grid>
          <Grid className='HeaderMobile' item xs={1}></Grid>

          <Grid item xs={1} align="right" >
            <IconButton style={{ color: '#e34791', marginTop: '12%' }} aria-label="cart" onClick={() => { navigate('/carrito') }}>
              <StyledBadge badgeContent={carrito.length} color="carrito">
                <ShoppingCartIcon />
              </StyledBadge>
            </IconButton>
            <IconButton style={{ color: '#e34791', marginTop: '12%' }} aria-label="cart"
            // onClick={() => { navigate('/carrito') }}
            >
              <StyledBadge color="carrito">
                <SearchIcon />
              </StyledBadge>
            </IconButton>
          </Grid>

          <Grid className='HeaderDesktop' align="center" item xs={1}>

            <Button sx={{ marginTop: '12%', fontWeight: 'bolder', fontSize: '0.975' }}
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <Avatar src={user ? user.FotoPerfil : ''} sx={{ bgcolor: '#e34791' }} alt="Sesion" ><AccountCircleIcon />  </Avatar>
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >

              {user ? <>
                {user.Nombre === "Admin" ? <MenuItem onClick={() => { GoToAdmin() }} className='menuItemHeader'>Ir a Administrador</MenuItem> : <></>}
                <MenuItem className='menuItemHeader'>Mi Perfil</MenuItem>
                <MenuItem className='menuItemHeader' onClick={() => { cerrarSesion() }}>Cerrar Sesión</MenuItem>
              </> : <>
                <MenuItem className='menuItemHeader' onClick={() => { GoToLogin() }}> Iniciar Sesión</MenuItem>
              </>}
            </Menu>
            {/* <Avatar  src={ user ? user.FotoPerfil : ''}  sx={{bgcolor: 'transparent' }} alt="Sesion" ><AccountCircleIcon />  </Avatar>  */}

          </Grid>

          <Grid item xs={0}>
          </Grid>
        </Grid>

        <Drawer
          anchor='left'
          open={state.openMenuMobil}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </div>
    </ThemeProvider>
  );
}

export default HeaderComponent