import React, { useContext, useState } from 'react'
import ToysContext from '../../context/ToysContext';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation, gql, useQuery } from "@apollo/client";
import Swal from 'sweetalert2'
import { initMercadoPago } from '@mercadopago/sdk-react';
import { Wallet } from "@mercadopago/sdk-react";
import Paypal from './paypal/PaypalComponent';

initMercadoPago('TEST-35803289-a9aa-475e-a92a-d952386f0ce6');


const CUPONDESCUENTO = gql`mutation CheckDiscount($code: String) {
  CheckDiscount(code: $code) {
    acumulative
    discount
    status
    type
    typeDiscount
    user
    validTo
  }
}`;

const MERCADOPAGO = gql`
mutation Mutation($data: MercadoPagoData) {
  MercadoPago(Data: $data)
}
`;


const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#005cbf'),
  backgroundColor: '#000000',
  '&:hover': {
    backgroundColor: '#000000',
  },
}));

const PaymentFinally = () => {
  const { envio, NextPayment, nextStepPayment, SetCupon, cupon, total, carrito } = useContext(ToysContext);
  const [state, setState] = useState({
    codigoerror: false,
    opencodigo: false,
    codigoPromo: "",
    preferenceId: [],
    showWallet: false
  });

  const [discountCode] = useMutation(CUPONDESCUENTO);
  const [MercadoPago] = useMutation(MERCADOPAGO);


  let totalArticulos = 0

  carrito.map((item) =>{
    totalArticulos = totalArticulos + item.cantidad;
  })


  const handleChange = () => {
    setState({ ...state, opencodigo: !state.opencodigo });
  }

  const handlecodigo = (e) => {
    setState({ ...state, codigoPromo: e.target.value })
  }
  const handleConfirmCode = async () => {


    try {

      const { data, loading, error } = await discountCode({
        variables: {
          code: state.codigoPromo
        }
      });

      if (data) {

        if (cupon.discount) {
          Swal.fire(
            'Ups w(°ｏ°)w',
            "Ya tienes un cupon activo, eliminalo para poder agregar uno nuevo",
            'warning'
          )
        } else {
          if (data.CheckDiscount.user === "") {
            Swal.fire(
              '☆*:.｡.o(≧▽≦)o.｡.:*☆',
              "Cupon aplicado!!!",
              'success'
            )
            SetCupon(data.CheckDiscount)
            setState({ ...state, codigoPromo: "", opencodigo: !state.opencodigo })
          } else {
            if (data.CheckDiscount.user != "usuraio") {
              Swal.fire(
                'Hey! ヾ( ￣O￣)ツ ',
                "Este cupon no es para tu cuenta, 凸(￣ヘ￣)",
                'error'
              )
            }
          }
        }
      }



    } catch (error) {
      Swal.fire(
        'Ups (╥﹏╥)',
        error.message.replace('GraphQLerror: ', ''),
        'error'
      )
    }
  }

  //MERCADO PAGO
  const setPreferenceId = (data) => {
    console.log("My data", data)
    setState({ ...state, preferenceId: data, showWallet: true });
  }

  const handleCancelCheckout = () => {
    setState({ ...state, showWallet: false });
  }

  const handleClick = async () => {
    
    
    let precioFinal = {total};
    
    const { data, loading, error } = await MercadoPago({
      variables: {
        data: { 
          quantity: totalArticulos, 
          price: precioFinal.total, 
          amount: precioFinal.total, 
          description: "Total del pedido en tu compra en BLOSSOM BY M" 
        }
      }
    });

    if(data){
      setPreferenceId(data.MercadoPago);
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} >
            <h3>INFORMACION DE CONTACTO <Button onClick={() => { NextPayment(false) }} variant="text" startIcon={<DriveFileRenameOutlineIcon />}>
              Editar
            </Button></h3>
          </Grid>
          <Grid item xs={6}>
            <h5>INFORMACIÓN DEL CONTACTO</h5>
            <p>{`${envio.nombre} ${envio.apellidos}`}</p>
            <p>{envio.email}</p>
            <p>+52 443 123 4567</p>
          </Grid>
          <Grid item xs={6} >
            <h5>DIRECCIÓN DE ENVÍO</h5>

            <p>{`${envio.calle} ${envio.colonia}`}</p>
            <p>{`C.P. ${envio.zipcode}`}</p>
            <p>{`${envio.ciudad}, ${envio.estado.value}, ${envio.pais.value}`}</p>
          </Grid>
          <Grid item xs={12} >
            <Accordion expanded={state.opencodigo} onChange={handleChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>¿TIENES UN CÓDIGO DE PROMOCIÓN?</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={2} ></Grid>
                  <Grid item xs={6} >
                    <TextField
                      required
                      error={state.codigoerror}
                      fullWidth
                      id="codigo-promo"
                      label="Código Promocional"
                      defaultValue=""
                      size="small"
                      value={state.codigoPromo}
                      onChange={handlecodigo}
                    />
                  </Grid>
                  <Grid item xs={1} >
                    <ColorButton variant="contained" onClick={handleConfirmCode}>APLICAR</ColorButton>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} >
            <h3>PAGO</h3>
          </Grid>
          <Grid item xs={12} >
            <p>
              Al realizar este pedido, acepto los Términos y condiciones y la Política de reembolso y cancelación. y autorizo que se me envie a la pagina de
              Paypal para pagar por mis productos.
            </p>
          </Grid>
          {nextStepPayment ?
            <Grid style={{display:'none'}} item xs={12} >
              <ColorButton variant="contained">REALIZAR PEDIDO</ColorButton>
            </Grid>
            : <></>}
        </Grid>
      </Box>

     
      
      {state.showWallet ?
      <> 
         <Grid style={{marginTop: 10}} item xs={12} >
         <Paypal
         descripcion="Compra de flores BLOSSOM BY M" 
         precio={ total} 
         cantidad={totalArticulos}
         />  
        </Grid>
        <Box style={{marginTop: 10}} sx={{ width: '100%' }}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={12} xl={12}>
              <Wallet
                initialization={{ preferenceId: state.preferenceId }}
                onReady={true}
              />
            </Grid>
            <Grid style={{marginTop: 10}} item xs={12} md={12} xl={12}>
              <ColorButton onClick={handleCancelCheckout}>CANCELAR</ColorButton>
            </Grid>
          </Grid>
        </Box>
        </>
        :
          <ColorButton onClick={handleClick}>SELECCIONAR METODO DE PAGO</ColorButton> }
      
      
    </>

  )
}



export default PaymentFinally;