import React, { useState } from "react";
import { useMutation, gql } from "@apollo/client";


const UPLOAD_IMAGE = gql`
mutation UploadImgProducts($file: String, $name: String) {
  UploadImgProducts(file: $file, name: $name)
}
`;


const  UploadImages = () => {
  const [uploadImage] = useMutation(UPLOAD_IMAGE,
    {
    context: {
      headers: {
        'apollo-require-preflight': true,
      },
      }});

  const [newImage, setnewImage] = useState(
  { base64:'',
    nameFile:''
  }
  );


  const ToBase = (file)=>
  {
    const img = file[0];
    const reader = new FileReader();
    reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
            setnewImage({base64:base64String, nameFile:img.name});
        console.log(base64String);
        // Logs wL2dvYWwgbW9yZ...
    };
    reader.readAsDataURL(img);

  }
  const ToServer =async () =>{

    const {data, loading} = await  uploadImage({
      variables: {
        file: newImage.base64,
        name: newImage.nameFile
      }  
    });

    console.log(data);
  } 
  return (
    <div style={{marginTop:'90px'}}>
      <input
        type="file"
        name=""
        id=""
        onChange={(e) => {
          ToBase(e.target.files);
        }}
      />
      <button
        onClick={(e) => {
          e.preventDefault();
          ToServer();
        }}
      >
        SEND FROM BACKEND
      </button>
    </div>
  );
}

export default UploadImages;