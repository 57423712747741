import React from 'react'
import fondoBanner from '../../assets/img/fondoBanner.avif';


const BannerPromocional = (data) =>{

    const {imagen, ancho, color, texto, imagenWidth, titulo, fontSize} = data;

    return(

        <>
        <div style={{width:'100%', height:ancho, background:color, display: "grid", alignContent: "space-evenly", fontWeight: "900",
             fontFamily: "cursive", fontSize:fontSize}}>
            {titulo !== undefined ? 
                <p style={{color:'black'}}> {titulo}</p>
                :
                null
            }
            {texto !== undefined ? 
            <p style={{color:'white', fontSize:'20px'}}> {texto}</p>

            :
            <img src={imagen} style={{height:'100%', width:imagenWidth} }/>
            }
        </div>
        </>
    )
}


export default BannerPromocional;