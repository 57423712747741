import React, {useContext} from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import SellIcon from '@mui/icons-material/Sell';
import ToysContext from '../../context/ToysContext';
import Swal from 'sweetalert2'


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});


const CardProduct = (data) => {

    const {addcar} = useContext(ToysContext);
    const {imagen, name, nomenclature, price, variation, id, cantidad, inStock } = data;

    const handleAddToCar = (product) => {
        console.log("MI FUNKO", product)
        addcar(product)
        Swal.fire('Listo!', 'El producto se agregó correctamente al carrito!.', 'success')
    }


    return (
        <>
            <Paper
            sx={{
                p: 2,
                margin: 'auto',
                maxWidth: 500,
                maxHeight:300,
                flexGrow: 1,
                backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
            }}
            >
                    <Grid container spacing={2}>
                        <Grid item>
                            <ButtonBase sx={{ width: 128, height: 128 }}>
                                <Img alt="complex" src={imagen} />
                            </ButtonBase>
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        {name}
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        {nomenclature}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {variation}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} columns={16} sx={{marginTop:'2%'}}>
                            <Grid item xs={7}>
                                <Button className='BotonesMasVendidos' size="small" variant="contained" endIcon={<ShoppingCartIcon />} 
                                onClick={()=>{handleAddToCar({'imagen':imagen, 'name':name, 'nomenclature':nomenclature, 'price':price, 'variation':variation, 'id':id, 'cantidad':cantidad, 'inStock':inStock })}}>
                                    Agregar
                                </Button>
                            </Grid>
                            <Grid item xs={9}>
                                <Button  className='BotonesMasVendidos' size="small" variant="outlined" startIcon={<SellIcon />}>
                                    Comprar Ahora
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
             </Paper>
        </>);

}



export default CardProduct