import React, { useState } from 'react';
import Chart from 'react-apexcharts'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Select from 'react-select';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import BarChartIcon from '@mui/icons-material/BarChart';
import Swal from 'sweetalert2'
import CleanHandsIcon from '@mui/icons-material/CleanHands';

const Charts = () => {


    const [state, setState] = useState({

        optionsAnual: {
            chart: {
                type: 'bar',
                height: 450
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Ene', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dic'],
            },
            yaxis: {
                title: {
                    text: '$ (Ventas Mensuales)'
                }
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "$ " + val + " Pesos"
                    }
                }
            }
        },
        series: [{
            name: 'Productos',
            data: [7600, 8500, 10100, 9800, 8700, 10500, 9100, 0,0,0,0,0]

        }, {
            name: 'Club BLOSSOM',
            data: [4400, 5500, 5700, 5600, 6100, 5800, 6300, 0, 0,0,0,0]
        }, {
            name: 'Suscripciónes',
            data: [3500, 4100, 3600, 2600, 4500, 4800, 5200, 0, 0,0,0,0]
        }],
        optionsMensual: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            title: {
              text: 'Productos Vendidos',
              align: 'left'
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            xaxis: {
              categories: ['1', '2', '3', '4', '5', '6', '7', '8', '9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " Piezas"
                    }
                }
            }
          },
        seriesMensual:[],
        SelectProductos:[],
        SelectMes:null
    });

    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor:'#000000',
        '&:hover': {
          backgroundColor: '#000000',
        },
      }));

    const OptionsFunkos = [
        { value: 'Roses By M', label: 'Roses By M' },
        { value: 'White Lush', label: 'White Lush' },
        { value: 'Pink Lush', label: 'Pink Lush' },
        { value: 'Tulip Exclusive', label: 'Tulip Exclusive' },
        { value: 'Roses n Tulips', label: 'Roses n Tulips' },
        { value: 'Lisianthus Trend', label: 'Lisianthus Trend' },
        { value: 'Hortensia Love', label: 'Hortensia Love' },
        { value: 'SunFlower Glam', label: 'SunFlower Glam' },
        { value: 'Chic & Gerbera', label: 'Chic & Gerbera' },
    ];
    const OptionsMeses = [
        { value: 'Enero', label: 'Enero' },
        { value: 'Febrero', label: 'Febrero' },
        { value: 'Marzo', label: 'Marzo' },
        { value: 'Abril', label: 'Abril' },
        { value: 'Mayo', label: 'Mayo' },
        { value: 'Junio', label: 'Junio' },
        { value: 'Julio', label: 'Julio' },
        { value: 'Agosto', label: 'Agosto' },
        { value: 'Septiembre', label: 'Septiembre' },
        { value: 'Octubre', label: 'Octubre' },
        { value: 'Noviembre', label: 'Noviembre' },
        { value: 'Diciembre', label: 'Diciembre' },
    ];

    const GenerarGrafica = () =>{

        if(validar()){
            buscarProductos();
        }else{
            Swal.fire({
                icon: 'warning',
                title: 'Oops',
                text: 'Selecciona al menos un producto y un mes',
              })
        }
    }

    const buscarProductos = () =>{
        // buscar en mongoDB

       const datafake = state.SelectProductos.map((item)=>{

            return {
                name:item.value,
                data:[Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),Math.floor(Math.random() * 100),Math.floor(Math.random() * 100)]
            }
       });

        setState({...state, seriesMensual:datafake})
    }

    const SeleccionarProductos = (item) =>{
        setState({...state,SelectProductos:item})
    }
    const SeleccionarMes = (item) =>{
        setState({...state,SelectMes:item.value, optionsMensual:{...state.optionsMensual,title:{ text:`Productos Vendidos en ${item.value}`,
        align: 'left'}}})
    }

    const validar = () =>{

        let mes = state.SelectMes !== null;
        let products = state.SelectProductos.length !== 0;

        return mes && products
    }

    const LimpiarGrafica = ()=>{
        setState({...state,SelectProductos:[],SelectMes:null,optionsMensual:{...state.optionsMensual,title:{ text:`Productos Vendidos`,
        align: 'left'}},seriesMensual:[]})
    }

    return (
        <>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2} columns={16}>
                    <Grid item xs={3} >
                        <h4>Grafica Mensual </h4>
                    </Grid>
                    <Grid item xs={13}>
                        <Grid container spacing={2} columns={10}>
                            <Grid item xs={3}>
                                <Select
                                    value={state.SelectProductos}
                                    isMulti
                                    name="products"
                                    className="basic-multi-select"
                                    classNamePrefix="select-productos"
                                    isSearchable={true}
                                    placeholder = "Producto/s"
                                    closeMenuOnSelect={false}
                                    options={OptionsFunkos}
                                    onChange={options => SeleccionarProductos(options)}
                                />
                            </Grid>
                            <Grid item xs={3}> 
                                <Select
                                        value={state.SelectMes ? state.SelectMes.value  : ''}
                                        name="Meses"
                                        className="basic-multi-select"
                                        classNamePrefix="select-Meses"
                                        isSearchable={true}
                                        placeholder = "Seleccione el Mes"
                                        options={OptionsMeses}
                                        onChange={options => SeleccionarMes(options)}
                                    />
                            </Grid>
                            <Grid item xs={2}>
                            <ColorButton  startIcon={<BarChartIcon />} onClick={GenerarGrafica}>
                                Generar Grafica
                            </ColorButton>
                            </Grid>
                            <Grid item xs={2}>
                            <ColorButton  startIcon={<CleanHandsIcon />} onClick={LimpiarGrafica}>
                                Limpiar Grafica
                            </ColorButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={16} style={{height:'350px', marginBottom:'5%'}}>
                        <Chart options={state.optionsMensual} series={state.seriesMensual} type="line" width={'100%'} height={'100%'} />
                    </Grid>
                    <Grid item xs={16} >
                    <h4>Grafica Anual</h4>
                    </Grid>
                    <Grid item xs={16} style={{height:'450px', marginBottom:'5%'}}>
                        <Chart options={state.optionsAnual} series={state.series} type="bar" width={'100%'} height={'100%'} />
                    </Grid>
                </Grid>
            </Box>

        </>
    );
}



export default Charts;