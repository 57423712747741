import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Grid from '@mui/material/Grid';
// import Funko_Logo from '../../assets/img/Funko_Logo.png';
// import Hot_Wheels_Logo from '../../assets/img/Hot_Wheels_Logo.png';
// import bandai from '../../assets/img/bandai.png';
// import fbpromouno from '../../assets/img/promovicboss1.jpeg';
// import fbpromodos from '../../assets/img/promoimgdos.jpeg';
// import fbpromotres from '../../assets/img/promoigmtres.jpeg';
// import fbvicbossbaneruno from '../../assets/img/vicbossBaneruno.jpeg'

const SectionWipes2 = () => {


return(
    
  <div style={{ overflow: 'hidden'}}>
    <Controller>
      <Scene
        triggerHook="onLeave"
        duration="300%"
        pin
      >
        <Timeline
          wrapper={<div id="pinContainer" />}
        >
            <section className="panel blue">
                    <Grid container spacing={2} columns={16} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                        {/* <img style={{width:'100%', marginTop:'10%'}} src={Funko_Logo}/> */}
                    </Grid>
                    <Grid xs={10}>
                        <img style={{width:'30%', marginTop:'10%'}} src='http://localhost:4000/graphql/img/nezuco2.png'/>
                        <img style={{width:'30%', marginTop:'10%'}} src='http://localhost:4000/graphql/img/KT-981.png'/>
                        <img style={{width:'30%', marginTop:'10%'}} src='http://localhost:4000/graphql/img/panter2.png'/>
                    </Grid>
                    <Grid xs={10} style={{backgroundColor:'#bababa'}}>
                            <img style={{width:'90%', marginTop:'0%'}} src='https://content.invisioncic.com/x311865/monthly_2023_02/HWC_Blog_Header_MainlinePrev2022.jpg.3fa59748cce593e6bfb2ad6383818d44.jpg'/>
                            
                        </Grid>
                        <Grid xs={6} style={{backgroundColor:'#bababa'}}>
                             {/* <img style={{width:'100%', marginTop:'10%'}} src={Hot_Wheels_Logo}/> */}
                      </Grid>
                    </Grid>
            </section>
            
          
          <Tween
            from={{ x: '-100%' }}
            to={{ x: '0%' }}
          >
             <section className="panel bordeaux">

             <Grid container spacing={2} columns={16} sx={{ flexGrow: 1 }}>
                    <Grid xs={6}>
                        {/* <img style={{width:'100%', marginTop:'20%'}} src={bandai}/> */}
                    </Grid>
                    <Grid xs={10}>
                        {/* <img style={{width:'30%', marginTop:'10%'}} src={fbpromouno}/>
                        <img style={{width:'30%', marginTop:'10%'}} src={fbpromodos}/> */}
                        {/* <img style={{width:'30%', marginTop:'10%'}} src='https://scontent.fmlm3-1.fna.fbcdn.net/v/t39.30808-6/341318282_1177876929589609_2344437312773902734_n.jpg?_nc_cat=107&ccb=1-7&_nc_sid=8bfeb9&_nc_ohc=PtDGonIE1YwAX_aQULq&_nc_ht=scontent.fmlm3-1.fna&oh=00_AfC6cv6td4wCMwVtOCtdJOFrwWE5b1m_35ZUICMENv3izw&oe=6442BD3A'/> */}
                    </Grid>
                    <Grid xs={10}>
                            {/* <img style={{width:'40%'}} src={fbpromotres}/> */}
                            
                        </Grid>
                        <Grid xs={6}>
                             <img style={{width:'80%'}} src='https://www.anbmedia.com/wp-content/uploads/2022/08/Cry-Babies-Magic-Tears-Licensing-Logo.png'/>
                      </Grid>
              </Grid>
             </section>
           
          </Tween>

          <Tween
            from={{ x: '100%' }}
            to={{ x: '0%' }}
          >
             <section className="panel bordeaux">
             {/* <img style={{width:'100%'}} className='lastImgMagicScroll' src={fbvicbossbaneruno}/> */}
             </section>
          </Tween>

          
        </Timeline>
      </Scene>
    </Controller>
  </div>
);

}
export default SectionWipes2;