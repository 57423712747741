import React, { useReducer } from "react";

import ToysContext from "./ToysContext";
import ToysReducer from "./ToysReducer";
import Swal from 'sweetalert2'

import { 
  ADD_FUNKOS,
  ADD_TO_CAR,
  DECREMENT_PRODUCT_FROM_CAR,
  DELETE_PRODUCT_FROM_CAR,
  PAGANDO,
  USER_DATA_SHIPPING,
  CARRITO,
  CUPON } from "../types";

const ToysState = ({children}) => {
    
  const initialState = {
    funkos: [],
    carrito: localStorage.getItem('carrito')? JSON.parse(localStorage.getItem('carrito')) : [],
    envio:{},
    nextStepPayment:false,
    total: localStorage.getItem('total')? JSON.parse(localStorage.getItem('total')) : 0,
    cupon:{}
  };

  const [state, dispatch] = useReducer(ToysReducer, initialState);


  const addFunko = (value) =>{
    dispatch({
        type:ADD_FUNKOS,
        payload:value
    })
  }

  const setShipping = (value) =>{

    dispatch({
      type:USER_DATA_SHIPPING,
      payload:value
    })
  }

  const addcar = (value) =>{
    dispatch({
        type:ADD_TO_CAR,
        payload:value
    })
  }

  const decrementProductFromCar = (value) =>{
    dispatch({
        type:DECREMENT_PRODUCT_FROM_CAR,
        payload:value
    })
  }

  const deleteProductFromCar = (value) =>{
    Swal.fire({
      title: 'De verdad quieres eliminar este producto del carrito?',
      icon: 'warning',
      showDenyButton: true,
      confirmButtonText: 'Si!',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
          try{
            dispatch({
                type:DELETE_PRODUCT_FROM_CAR,
                payload:value
            })
            Swal.fire('Listo!', 'El producto se eliminó correctamente del carrito.', 'success')
          }catch(e){
              console.log('Error->', e);
              Swal.fire(
                  'Ha ocurrido un error!',
                  e,
                  'error'
              )
          }
        
          
      } else if (result.isDenied) {
          Swal.fire('La operación fue cancelada', '', 'info')
      }   
    })

  }

  const NextPayment = (value) =>{
    dispatch({
        type:PAGANDO,
        payload:value
    })
  }

  const SetCupon = (value) =>{
    dispatch({
        type:CUPON,
        payload:value
    })
  }

 
  return (
    <ToysContext.Provider
      value={{
        cupon: state.cupon,
        funkos: state.funkos,
        nextStepPayment: state.nextStepPayment,
        addFunko,
        addcar,
        decrementProductFromCar,
        deleteProductFromCar,
        NextPayment,
        carrito: state.carrito,
        envio : state.envio,
        setShipping,
        total : state.total,
        SetCupon
      }}
    >
    {children}

    </ToysContext.Provider>
  );
};

export default ToysState;