import React, {useState} from 'react'
import { useQuery, gql, useMutation } from "@apollo/client";
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Swal from 'sweetalert2'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from 'react-icons/fa';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';

const REDES = gql`
query Query {
  AllSocialConfig {
    activo
    id
    label
    mensaje
    url
  } 
}
`;

const UPDATEREDES = gql`
mutation Mutation($opcion: String, $config: [ConfigSocialInput]) {
  SocialConfigCrud(opcion: $opcion, config: $config) {
    activo
    id
    label
    mensaje
    url
  }
}

`;


const SocialCongig = () =>{

    const {loading,error,data,} = useQuery(REDES);
    const [updateredes] = useMutation(UPDATEREDES);
    const [state, setState]= useState({
        redes:[],
        buscar: true,
        check:true,
        Twitter:{
        
        label: "Twitter"
        },
        Pinterest:{
       
        label: "Pinterest"
        },
        Instagram:{
       
        label: "Instagram"
        },
        Tiktok:{
       
        label: "Tiktok"
        },
        Youtube:{
       
        label: "Youtube"
        },
        Whatsapp:{
      
        label: "Whatsapp"
        },
        Facebook:{
       
        label: "Facebook"
        },
    }); 


    const ColorButton = styled(Button)(({ theme }) => ({
        color: theme.palette.getContrastText('#005cbf'),
        backgroundColor: '#000000',
        '&:hover': {
            backgroundColor: '#000000',
        },
    }));

    if(loading){return(<>Cargando...</>)}

    if(error){return(<>Ups Algo Salio mal...</>)}

    if(data && state.buscar){
        setState({...state, redes: [...data.AllSocialConfig], buscar:false});
    }

    const Actualizar = async() =>{

       const toServer = [
            state.Twitter,
            state.Pinterest, 
            state.Instagram, 
            state.Tiktok, 
            state.Youtube, 
            state.Whatsapp, 
            state.Facebook
        ]
        const { data, loading, error } = await updateredes({
            variables: {
                config: toServer,
                opcion: "Update",
            }
        });

        if(data.SocialConfigCrud){
            setState({...state, 
                redes:data.SocialConfigCrud,
                Twitter:{label: "Twitter"},
                Pinterest:{label: "Pinterest"},
                Instagram:{label: "Instagram"},
                Tiktok:{label: "Tiktok"},
                Youtube:{label: "Youtube"},
                Whatsapp:{label: "Whatsapp"},
                Facebook:{label: "Facebook"},});
            
            
            Swal.fire(
                'Listo!',
                'Redes sociales actualizadas con exito',
                'success'
              )
        }
        if(error){
            Swal.fire(
                'Ups!',
                'Algo salio mal, intenta mas tarde',
                'error'
              )
        }

    }

    const cambiarStatus = (e, red)=>{
        setState({...state, [red]:{...state[red], activo:e.target.checked}});
    }

    const cambiarurl = (e, red) =>{

        setState({...state, [red]:{...state[red], url:e.target.value}});
    }

    const cambiarMensaje = (e, red) =>{
        setState({...state, [red]:{...state[red], mensaje:e.target.value}});
    }

    return(
    <>
    {data ? 
    
    <>
    
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 1, sm: 1, md: 1 }}>
        
          {state.redes.map((item, index) =>(
                <Grid item xs={1} sm={1} md={1} key={`key${index}${item.label}`}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 3, sm: 12, md: 12 }}>
                    {item.label !== 'Whatsapp' ? 
                    <>
                        <Grid sx={{marginTop:'1%'}} item xs={1} sm={1} md={1} key={`key2${index}${item.label}`}>
                            {item.label === 'Facebook' ?  <FacebookIcon /> : 
                            item.label === 'Instagram' ?  <InstagramIcon /> :
                            item.label === 'Youtube' ?  <YouTubeIcon   /> : 
                            item.label === 'Tiktok' ?  <FaTiktok /> : 
                            item.label === 'Twitter' ? <TwitterIcon /> : <PinterestIcon /> } 
                        </Grid>
                        <Grid item xs={1} sm={5} md={5} key={`cajatext${index}${item.label}`}>
                            <TextField fullWidth id={`Url${index}${item.label}`} label={item.label} onChange={(e) =>cambiarurl(e, item.label)} value={ state[item.label].url || state[item.label].url === "" ? state[item.label].url : item.url } variant="outlined" />
                        </Grid>
                        <Grid  align="left" item xs={1} sm={6} md={6} key={item.label}>
                            <input type="checkbox"  id={`check${index}${item.label}`} defaultChecked={state.redes[index].activo} onChange={(e)  => cambiarStatus(e, item.label)}/>
                            Activar
                        </Grid>
                    </>
                    : 
                    <>
                        <Grid sx={{marginTop:'1%'}} item xs={1} sm={1} md={1} key={'Whatsappbox'}>
                             <WhatsAppIcon color={index % 2 === 0 ? 'SocialColor' : ''}/>
                        </Grid>
                        <Grid item xs={1} sm={2} md={2} key={item.label}>
                            <TextField fullWidth id={`Url${index}${item.label}`} label={item.label} value={ state[item.label].url || state[item.label].url === "" ? state[item.label].url : item.url } onChange={(e) =>cambiarurl(e, item.label)} variant="outlined" />
                        </Grid>
                        <Grid item xs={1} sm={6} md={6} key={'cajaWhats'}>
                            <TextField fullWidth id={`mensaje`} label={'Mensaje'} value={ state[item.label].mensaje || state[item.label].mensaje === "" ?  state[item.label].mensaje : item.mensaje } onChange={(e) => cambiarMensaje(e, item.label)} variant="outlined" />
                        </Grid>
                        <Grid item xs={1} sm={3} md={3} key={'checkwhats'}>
                        <input type="checkbox"  id={`check${index}${item.label}`} defaultChecked={state.redes[index].activo} onChange={(e)  => cambiarStatus(e, item.label)}/>
                            Activar
                        </Grid>
                    </>
                    }
                </Grid>
              </Grid>
          ))}
          
          <Grid item xs={1} sm={1} md={1} key={'redes'}>
                <ColorButton onClick={()=>{Actualizar()}}>Guardar</ColorButton>
          </Grid>
      
      </Grid>
    </Box>
    
    
    </> 



    : 
    <></>
    }
    
    </>)
}




export default SocialCongig;