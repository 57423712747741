import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

const ProductPayment = (Data) =>{


    const {Nombre, Coleccion, cantidad, precio, Imagen} = Data;


    return (

        <>

<Card sx={{ display: 'flex' }}>
        <CardMedia
        component="img"
        sx={{ width: 100 }}
        image={Imagen}
        alt="Prueba"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }}>
          <Typography component="div" variant="h5">
            {Nombre}  
          </Typography>
          {/* <Typography  variant="subtitle1" color="text.secondary" component="div">
            {Nombre}
          </Typography> */}
          <Typography variant="subtitle1" color="text.secondary" component="div">
           Cantidad: {cantidad}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
           Precio: {precio}
          </Typography>
        </CardContent>
      </Box>
    </Card>
        
        </>
    )
}




export default ProductPayment;