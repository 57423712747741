import React from "react";

const Footer = () => {
  return (
    <div className="footer text-center text-white py-2">

      <div className="d-flex justify-content-center align-items-center ">
        <div className="">
          <div>Información</div>
        </div>
        <div className="mx-5 ">
          <div>Faqs</div>
          <div>Envíos</div>
          <div>Contact@gmail.com</div>
        </div>
        <div className="">
          <div>
            <div>Aviso de Legal</div>
            <div>Aviso de Privacidad</div>
          </div>
        </div>

      </div>


      <div className="mt-3" style={{marginRight:'50px'}}>
        <div className="d-flex justify-content-center align-items-center ">
          <small>BLOSSOM, Copyright &copy; 2024</small>
        </div>
      </div>
    </div>
  );
};

export default Footer;