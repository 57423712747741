import React, {useState, useContext, useEffect} from 'react'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductPayment from './ProductPaymentComponent';
// import funko_prueba from '../../assets/img/funko_prueba.png';
// import funko_prueba_dos from '../../assets/img/funko_prueba_dos.png'
import ToysContext from '../../context/ToysContext';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Swal from 'sweetalert2'


const Payment = () => {


  const [state, setState] = useState(true);


  const {carrito, total, envio, cupon, SetCupon} = useContext(ToysContext);
  const [checked, setChecked] = useState({
    rappi:false,
    redpack:false,
    redpackcien:false,
    bodega:false,
    totalEnvio:0,
    totalDescuento:0,
    cuponagregado:true
  });
  const handleChange = () =>{
    setState(!state);
  }

  let totalArticulos = 0

  carrito.map((item) =>{
    totalArticulos = totalArticulos + item.cantidad;
  })

  const metropolitana = ["Jalisco","Querétaro","Estado de México","Guerrero","Colima","Ciudad de México","Guanajuato",]

  const handleRemoCupon = () => {

    Swal.fire(
      '.｡･ﾟﾟ･(＞_＜)･ﾟﾟ･｡.',
      "Se elimino el cupon aplicado",
      'success'
    )

    SetCupon({})
    calcularTotal(false)
  }

  const handleEnvio = (rappi,red,redcien,bodega)=>{
    
    let envio = 0

    if(rappi){
      envio = 49
    }else if(red){
      envio = 99
    }else if(redcien){
      envio = 199
    }


    setChecked({
      ...checked,
      rappi:rappi,
      redpack:red,
      redpackcien:redcien,
      bodega:bodega,
      totalEnvio: envio
    })

    

  }

  const calcularTotal = (op) => {
   
    let cupontotal = 0

    if(cupon.type && op){

      if(cupon.type === "PORCENTAJE"){
        cupontotal = total * parseFloat(`0.${cupon.discount}`);
      }else{
        cupontotal = total - cupon.discount;
      }

    }

    
    
    setChecked({
      ...checked, totalDescuento: cupontotal <= 0 && op ? total : cupontotal, cuponagregado:!op
    })

  }
//   useEffect(() => {
//     let isMounted = true
//     if (isMounted) {
//         calcularTotal()
//     }
    
//     return () => {
//         isMounted = false
//     }
// }, [])


if(cupon.type && checked.cuponagregado){
 
  calcularTotal(true)

}

  return (

    <>
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} >
            <h3>RESUMEN</h3>
          </Grid>
          <Grid item xs={6}>
            <p>Total Articulos</p>
          </Grid>
          <Grid item xs={6} >
            <p>{totalArticulos}</p>
          </Grid>
          <Grid item xs={6}>
            <h5>Total Parcial</h5>
          </Grid>
          <Grid item xs={6} >
            <p>${total}</p>
          </Grid>
          {cupon.type ? 
           <>
          <Grid item xs={6}>
            <h5>Cupon</h5>
          </Grid>
          <Grid item xs={6} >
            <p> {cupon.type === "NUMERICO" ? `$${cupon.discount}` : `${cupon.discount}%`} de  descuento  <IconButton color="secondary"  aria-label="delete" onClick={handleRemoCupon} ><DeleteIcon fontSize="small"/></IconButton></p>
          </Grid>
          </>
          :
          <></>
          }
          {envio.ciudad ? 
          <>
          <Grid item xs={6}>
            <h5>Envio</h5>
          </Grid>
          <Grid item xs={6} >
          {envio.ciudad?.toLowerCase() === 'morelia' ? 
          <>
            <p><Checkbox checked={checked.rappi} onChange={()=>handleEnvio(!checked.rappi,false,false,false)} size="small"/> Rappi $49</p>
            <p><Checkbox checked={checked.bodega} onChange={()=>handleEnvio(false,false,false,!checked.bodega)} size="small"/> Recoger en Tienda</p>
            {/* <p>Calle Francisca Xaviera de Tapia 370, Col Bocanegra, Morelia Michoacan</p> */}
          </> 
          :

          metropolitana.includes(envio.estado.value) ? 
          
             <p><Checkbox checked={checked.redpack}  onChange={()=>handleEnvio(false,!checked.redpack,false,false)} size="small"/> RedPack $99</p>
          :
             <p><Checkbox checked={checked.redpackcien} onChange={()=>handleEnvio(false,false,!checked.redpackcien,false)} size="small"/> RedPack $199</p>
          }   
          </Grid>
          </>
          :
          <></>
          }
          <Grid item xs={12} >
            <Divider />
          </Grid>
          <Grid item xs={6} >
            <h5>TOTAL</h5>
          </Grid>
          <Grid item xs={6} >
            <h5>${(total - checked.totalDescuento ) + checked.totalEnvio}</h5>
          </Grid>
          <Grid item xs={12} >
            <Divider />
          </Grid>
          <Grid item xs={12} >
            <h4>SU PEDIDO :D</h4>
          </Grid>
          <Grid item xs={12} >
            <Accordion expanded={state} onChange={handleChange}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>TUS PRODUCTOS</Typography>
              </AccordionSummary>
              <AccordionDetails>

                { carrito.map((item) =>(
                  <>
                    <Divider />
                    <ProductPayment
                    Imagen = {item.imagen}
                    Nombre = {item.name}
                    Coleccion = {item.variation}
                    cantidad = {item.cantidad}
                    precio = {`$${item.price}`}
                    />
                    </>
                ))}
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      </Box>
    </>

  )

}

export default Payment;