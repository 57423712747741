import React, {useState, useEffect} from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Modal from '@mui/material/Modal';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useMutation, gql } from "@apollo/client";
import Swal from 'sweetalert2'

const styleModal = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText('#005cbf'),
    backgroundColor:'#000000',
    '&:hover': {
      backgroundColor: '#000000',
    },
  }));



const ACTIVARSLIDER = gql`
mutation Mutation($sliderOnAndOffId: String, $status: Boolean) {
  SliderOnAndOff(id: $sliderOnAndOffId, status: $status) {
    description
    id
    status
    url
  }
}
`;


const ELIMINARLIDER = gql`
mutation Mutation($sliderDeleteId: String) {
    SliderDelete(id: $sliderDeleteId)
}
`;


const ADDSLIDER = gql`
mutation Mutation($opcion: String!, $imagenes: [ImagenInput!]) {
  SliderCrud(opcion: $opcion, imagenes: $imagenes) {
    description
    id
    status
    url
  }
}
`;


const SliderAdmin = ({data}) => {

    const [activarslider] = useMutation(ACTIVARSLIDER,
        {
        context: {
          headers: {
            'apollo-require-preflight': true,
          },
          }});
    
    const [eliminarslider] = useMutation(ELIMINARLIDER);
    const [addslider] = useMutation(ADDSLIDER);

    const [state, setState] = useState({
        data: [],
        modalOpen:false,
        nombre:'',
        descripcion:'',
        imagenName:null,
        descripcionerror:false,
        imgError:false,
        img:null
    });


    const Eliminar = async (id) =>{
        try{
            const {data:response, loading, error} = await  eliminarslider({
                variables: {
                    sliderDeleteId:id,
                }  
              });
    
              if(response.SliderDelete === "success"){

              
              
                const array = state.data.filter((item) => item.id !== id )


                // array.unshift(response.SliderOnAndOff);

                setState({
                    ...state,
                    data: array
                })

              }
        }catch(e){
            console.log('Error->', e);
        }
    }
    const statusupdate = async (id, status) =>{
        try{
            const {data:response, loading, error} = await  activarslider({
                variables: {
                    sliderOnAndOffId:id,
                    status: !status
                }  
              });
    
              if(response){
              
                const array = state.data.filter((item) => item.id !== response.SliderOnAndOff.id )


                array.unshift(response.SliderOnAndOff);

                setState({
                    ...state,
                    data: array
                })

              }
        }catch(e){
            console.log('Error->', e);
        }
    }

    const valid = () => {

        const descripcion = state.descripcion === '' ;
        const imagen = state.img === null ;


        setState({...state, descripcionerror: descripcion, imgError: imagen});

        return !descripcion && !imagen

    }

    const addSlider = async ()=>{
        
        if(valid()){

            try{
                const {data:response, loading, error} = await  addslider({
                    variables: {
                        opcion:"Add",
                        imagenes:[
                            {
                                "description": state.descripcion,
                                "img": state.img,
                                "nombre": state.imagenName,
                                "status": true,
                                "url":""
                            }
                        ],
                    }  
                  });
        
                  if(response){

                    
    
                    state.data.unshift(response.SliderCrud[0])

                    setState({
                        ...state,
                        modalOpen:false,
                        nombre:'',
                        descripcion:'',
                        imagenName:null,
                        descripcionerror:false,
                        imgError:false,
                        img:null
                    })
                    Swal.fire(
                        'Agregado!',
                        'Tu imagen fue agregada!!',
                        'success'
                      )
    
                  }
            }catch(e){
                console.log('Error->', e);
            }

           
        }else{
            Swal.fire({
                icon: 'error',
                title: ':c ',
                text: 'Por favor llena todos los campos :D !',
              })
        }

    }

    const handleClose =() =>{
        setState({...state,
            modalOpen:false,
            nombreError:false,
            nombre:'',
            descripcion:'',
            imagenName:null,
            img:null 
        });
    }
    
    const guardarimagen = (e) => {     
        const img  =  e.target.files;
        const foto = img[0];

        const reader = new FileReader();
        reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
            .replace('data:', '')
            .replace(/^.+,/, '');
            setState({...state,img:base64String,imagenName: foto.name});
        };
        reader.readAsDataURL(foto);
       
    }
    
    const openmodal = () => {
        setState({...state, modalOpen:true,});
    }
    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            setState({...state,data: [...data]});
        }

        return () => {
            isMounted = false
        }
    }, [])
    

    return (
        <>
        <div >
        <div >
                <Grid sx={{ marginTop: 3 }} container spacing={2}>
                    <Grid align="center" item xs={12} sm={6} md={6}>
                    </Grid>
                    <Grid align="right" item xs={12} sm={6} md={6}>
                        <ColorButton  startIcon={<AddPhotoAlternateIcon />} onClick={openmodal}>
                            Nueva imagen Para Slider
                        </ColorButton>
                    </Grid>
                </Grid>
        </div>

        <Box sx={{ flexGrow: 1, marginLeft: '3%', marginBottom:'2%', marginTop:'5%', height: state.data.length === 0 ? "90vh" : "auto"}}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {
            
            state.data.length === 0 ?
                <p>No tienes imágenes para mostrar en tu slider principal :c</p>
            :
            
            state.data.map((item) =>(
            
            <Grid item xs={2} sm={4} md={4} key={item.id}>
            <Card sx={{ maxWidth: 345 }} key={item.id} >
                <CardMedia
                    component="img"
                    alt="Imagen 1"
                    height="140"
                    image={item.url}
                />
                <CardContent>
                    <Typography variant="body2" color="text.secondary">
                    Descripción {item.description}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        
                    Status: {item.status ? 'Activada' : 'Desactivada' }
                    *para {item.status   ? ' desactivar y que no se muestre' : ' Activar y que  se muestre'} en el Slider principal solo Desactive en el boton de abajo
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button onClick={()=>{statusupdate(item.id, item.status)}} size="small">{item.status  ? 'Desactivar' : 'Activar'}</Button> 
                    <Button onClick={() =>{Eliminar(item.id)}} size="small">Eliminar</Button>
                </CardActions>
                </Card>
            </Grid>
            ))}
            
            
            </Grid>
        </Box>


        <Modal
                open={state.modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleModal}>
                    <Grid container spacing={2} sx= {{ justifyContent: 'center', alignItems: 'center' }}>
                        <Grid item xs={8}>
                            <InputLabel id="label-desc"  style={{paddingBottom:'10%'}} className="mx-2">Descripción</InputLabel>
                                <TextField
                                    fullWidth
                                    id="desc"
                                    error={state.descripcionerror}
                                    variant="standard"
                                    type='text'
                                    multiline
                                    rows={1}
                                    rowsMax={10}
                                    value={state.descripcion}
                                    onChange={(e) =>{setState({...state,descripcion: e.target.value})}}
                                    /> 
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} sx= {{ justifyContent: 'center', alignItems: 'center', marginTop:'1%' }}>
                        <Grid itme xs={2}></Grid>
                        <Grid item xs={6}>
                        
                        <TextField
                            style={{paddingTop:'15%'}}
                            variant="standard"
                            fullWidth
                            hiddenLabel
                            disabled
                            error={state.imgError}
                            id="filled-hidden-label-normal"
                            value={state.imagenName ? state.imagenName : "Imagen"}
                            size="small"
                        />
                        </Grid>
                        <Grid item xs={4}>
                        <Button variant="contained" component="label">
                            <FileUploadIcon/>
                        <input type="file" hidden onChange={guardarimagen} accept='.png , .jpg , .webp'/>
                        </Button>
                        </Grid>
                    </Grid> 
                    <Grid container spacing={2} sx= {{ justifyContent: 'center', alignItems: 'center', paddingTop:'4%'}}>
                        <Grid item xs="auto">
                                <ColorButton variant="outlined" 
                                type='button'
                                endIcon={
                                    <CloseIcon />
                                }
                                onClick={handleClose}>
                                Cancelar
                                </ColorButton>
                        </Grid>
                        <Grid item xs={5}></Grid>
                        
                        <Grid item xs="auto">
                                <ColorButton variant="outlined" 
                                type='button'
                                endIcon={
                                    <SaveIcon />
                                }
                                onClick={() => addSlider()}>
                                Guardar
                                </ColorButton>
                        </Grid>
                    </Grid>
                </Box>
        </Modal>
        </div>
        </> );
}

export default SliderAdmin;