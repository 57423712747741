import './App.css';
import './style/global.css'
import {ApolloProvider} from '@apollo/client'
import ToysState from './context/ToysState';
import {
  BrowserRouter as Router,
  useRoutes,
} from "react-router-dom";
import client from './config/apollo';
import HomeView from './views/HomeView';
import HeaderComponent from './components/HeaderComponent';
import Footer from './components/FooterComponent';
import Login from './components/LoginComponent';
import UserView from './views/UserView';
import AdminView from './views/AdminView';
import ProductView from './views/ProductView';
import ProductosView from './views/ProductosView';
import Test from './views/Test';
import Carrito from './views/Carrito';
import PaymentView from './views/PaymentView';
import NotFound from './components/NotFound';

import ClubBlossom from './views/clubBlossom';
import Suscripciones from './views/Suscripciones';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const RouterApp = () => {
  let routes = useRoutes([
    { path: "/", element: <HomeView /> },
    { path: "/login", element: <Login /> },
    { path: "/usuario", element: <UserView /> },
    { path: "/administrador", element: <AdminView /> },
    { path: "/producto/:id", element: <ProductView /> },
    { path: "/Club-Blossom", element: <ClubBlossom /> },
    { path: "/Suscripciones", element: <Suscripciones /> },
    // { path: "/categorias/:titulo", element: <ProductosView/>},
    { path: "/Productos", element: <ProductosView/>},
    { path: "/test", element: <Test/>},
    { path: "/carrito", element: <Carrito/>},
    { path: "/Pago", element: 
    <PayPalScriptProvider
    options={{
      "clientId":process.env.REACT_APP_PAYPAL
    }}
    >
        <PaymentView/>
      </PayPalScriptProvider>},
    { path: "*", element: <NotFound/>}
  ]);
  return routes;
}

function App() {
  return (
    <div className="App">
      <ApolloProvider client={client}>
        <ToysState>
          <Router basename="/">
            <HeaderComponent/>
              <RouterApp />
             <Footer/> 
          </Router>
        </ToysState>
     </ApolloProvider>
    </div>
  );
}

export default App;