import React from 'react'
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { FaTiktok } from 'react-icons/fa';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TwitterIcon from '@mui/icons-material/Twitter';

const SocialNetwork = ({data}) => {

    const theme = createTheme({
        palette: {
          SocialColor: {
            main: '#f3e1ab',
            // main: '#f3e1ab',
            // contrastText: '#fff',
          },
        },
      });

    return(
        <>
        <ThemeProvider theme={theme}>
            <Stack direction="row" spacing={2}  alignItems="center" justifyContent="center" sx={{backgroundColor:'white', height:'100px'}} >
                {data.map((item, index) =>(
                   
                <Link href={item.url} target='_blank'>
                     <Avatar sx={index % 2 === 0 ? {  bgcolor: '#eda1ad', width: 80, height: 80 } : { bgcolor: '#eda1ad',  width: 80, height: 80  }}>
                          {item.label === 'Facebook' ?  <FacebookIcon color={'SocialColor'}/> : 
                          item.label === 'Instagram' ?  <InstagramIcon color={'SocialColor'}/> :
                          item.label === 'Youtube' ?  <YouTubeIcon  color={'SocialColor'} /> : 
                          item.label === 'Tiktok' ?  <FaTiktok color={'SocialColor'} style={{color:"#f4e2ab"}} /> : 
                          item.label === 'Twitter' ? <TwitterIcon color={'SocialColor'}/> : <PinterestIcon color={'SocialColor'}/> }  
                     </Avatar>
                </Link>
                    
                ))}
            </Stack>
        </ThemeProvider>
        </>
    );
}




export default SocialNetwork;